import { FaSignOutAlt, FaUser } from 'react-icons/fa';
import { Outlet } from 'react-router-dom';

import useSWR from 'swr';

import { SERVICE_TYPE } from '../SERVICE_TYPE';
import { api } from '../api';
import { Logo } from '../components/Logo';
import { AuthUser } from '../types';
import { BankInfoAlert } from './BankInfoAlert';

export const AdminRoot: React.FC = () => (
  <div>
    <Header />
    {SERVICE_TYPE === 'pet' && (
      <div className="bg-green-100 p-1 text-center">
        <div className="text-xs">
          本サイトにおける収益金の一部は、殺処分ゼロ運動に寄付されます。
        </div>
      </div>
    )}
    <BankInfoAlert />
    <Outlet />
  </div>
);

const Header: React.FC = () => {
  const fetcher = (url: string) => api(url).then((res) => res.data);
  const { data: user } = useSWR<AuthUser>('/me/', fetcher);
  return (
    <div className="bg-blue-300 px-4 py-2 flex items-center">
      <Logo className="inline-block h-6 mr-2" />
      <div className="flex-1">主催者用ページ</div>
      {user && (
        <>
          <div className="flex items-center mx-2">
            <FaUser className="mr-1" />
            <div className="mr-2">{user.email}</div>
          </div>
          <a href="/accounts/logout" className="flex items-center mx- mx-22">
            <FaSignOutAlt className="mr-1" />
            ログアウト
          </a>
        </>
      )}
    </div>
  );
};
