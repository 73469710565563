import { useCallback, useEffect, useRef, useState } from 'react';

import { differenceInSeconds } from 'date-fns';
import { useInterval } from 'usehooks-ts';

export const useTimeCountdown = (limit: Date | null): string | null => {
  const [count, setCount] = useState<number | null>(null);

  const func = useCallback(() => {
    if (limit === null) {
      setCount(null);
      return;
    }
    const diff = differenceInSeconds(limit, new Date());
    if (diff < 0) {
      setCount(null);
    } else {
      setCount(diff);
    }
  }, [limit]);

  useEffect(() => {
    func();
  }, [limit, func]);

  useInterval(func, 1000);

  if (count === null) {
    return null;
  } else {
    const minutes = Math.max(Math.floor(count / 60), 0);
    const seconds = Math.max(count % 60, 0);
    const t = `${minutes}:${seconds.toString().padStart(2, '0')}`;
    return t;
  }
};
export const useCountdownAction = (
  handler: () => Promise<void>,
  seconds: number,
): { start: () => Promise<void>; count: number | null } => {
  const countRef = useRef(seconds);
  const enabledRef = useRef(false);
  const [count, setCount] = useState<number | null>(null);
  const start = async () => {
    if (enabledRef.current) return;
    await new Promise<void>((resolve) => {
      enabledRef.current = true;
      countRef.current = seconds;
      setCount(seconds);
      const interval = setInterval(() => {
        countRef.current -= 1;
        setCount(countRef.current);
        if (countRef.current === 0) {
          clearInterval(interval);
          setCount(null);
          enabledRef.current = false;
          handler().then(() => {
            resolve();
          });
        }
      }, 1000);
    });
  };
  return { start, count };
};

/**
 * 現在時刻が指定した日付より前かどうかを返します
 * もしdateがnullの場合はfalseを返します
 * @param date 比較する日付
 * @param checkInterval チェック間隔
 * @returns 現在時刻より前の場合はtrue
 */
export const useBefore = (
  date: Date | null,
  checkInterval?: number,
): boolean => {
  const [before, setBefore] = useState<boolean>(false);
  const judge = useCallback(() => {
    if (date === null) {
      setBefore(false);
      return;
    }
    const now = new Date();
    setBefore(now.getTime() < date.getTime());
  }, [date]);
  useEffect(() => {
    judge();
  }, [date, judge]);
  useInterval(judge, checkInterval || 1000);
  return before;
};
