import { SERVICE_TYPE } from '../SERVICE_TYPE';
import LogoPet from '../img/logo_pet.svg';
import LogoPiano from '../img/logo_piano.png';
import LogoShow from '../img/logo_show.svg';

const getLogo = () => {
  switch (SERVICE_TYPE) {
    case 'piano':
      return LogoPiano;
    case 'show':
      return LogoShow;
    case 'pet':
      return LogoPet;
  }
};

export const Logo: React.FC<{ className?: string }> = ({ className }) => {
  const logo = getLogo();
  return <img src={logo} alt="logo" className={className} />;
};
