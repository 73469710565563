import React from 'react';

import { NotFoundPage } from '.';
import { SERVICE_TYPE } from './SERVICE_TYPE';
import { TopPagePet } from './TopPagePet';
import { TopPagePiano } from './TopPagePiano';
import { TopPageShow } from './TopPageShow';

export const TopPage: React.FC = () => {
  if (SERVICE_TYPE === 'pet') return <TopPagePet />;
  if (SERVICE_TYPE === 'show') return <TopPageShow />;
  if (SERVICE_TYPE === 'piano') return <TopPagePiano />;
  return <NotFoundPage />;
};
