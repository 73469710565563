import clsx from 'clsx';

import { SERVICE_TYPE } from './SERVICE_TYPE';
import { Participant } from './types';

export const UserIcon: React.FC<{
  name: string;
  participants: Participant[];
  className?: string;
}> = ({ name, participants, className }) => {
  const participant = participants.find((p) => p.name === name);
  if (participant == null) {
    return null;
  }
  const url =
    SERVICE_TYPE === 'pet'
      ? `https://d25w0w3otl2k95.cloudfront.net/images/?fclr=${
          participant.bg
        }&iclr=${participant.fg}&name=${encodeURIComponent(participant.name)}`
      : `https://d25w0w3otl2k95.cloudfront.net/images/note/?fclr=${
          participant.bg
        }&iclr=${participant.fg}&name=${encodeURIComponent(participant.name)}`;

  return (
    <img
      src={url}
      alt={name}
      className={clsx('mr-2 inline-block rounded-full', className)}
    />
  );
};
