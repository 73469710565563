import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { LIVE_ALWAYS_ENABLED, SERVICE_TYPE } from '../SERVICE_TYPE';
import { api } from '../api';
import { Modal } from './Modal';

const eventTypes = [
  { id: 'birthday', name: 'お誕生日会' },
  //{ id: 'memorial', name: 'お別れ会' },
] as const;

type AdminCreateEvent = {
  pet_name: string;
  event_name: string;
};

type AdminCreateResp = AdminCreateEvent & {
  id: string;
};

const generateEvent = (name: string, eventType: string): AdminCreateEvent => {
  switch (SERVICE_TYPE) {
    case 'pet':
      return {
        pet_name: name,
        event_name: `${name} お誕生日会`,
      };
    case 'piano':
      return {
        pet_name: name,
        event_name: `${name} ピアノリサイタル`,
      };
    case 'show':
      return {
        pet_name: name,
        event_name: `${name} リサイタル`,
      };
  }
};

export const AdminEventCreateModal: React.FC<{ onClose?: () => void }> = ({
  onClose,
}) => {
  type Inputs = {
    pet_name: string;
    event_type: string;
  };

  const { register, handleSubmit } = useForm<Inputs>();
  const navigate = useNavigate();

  const onSubmit = async (data: Inputs) => {
    const event = generateEvent(data.pet_name, data.event_type);
    const res = await api.post<AdminCreateResp>('/manager/ceremony/', event);

    if (LIVE_ALWAYS_ENABLED) {
      await api.patch(`/manager/ceremony/${res.data.id}/`, {
        live_enabled: 'yes',
      });
    }

    console.log(res.data);
    navigate(`/manager/event/${res.data.id}/setup/`);
  };

  return (
    <Modal>
      {SERVICE_TYPE === 'pet' && (
        <h1 className="mb-2 text-2xl font-bold">新規お誕生日会作成</h1>
      )}
      {SERVICE_TYPE === 'piano' && (
        <h1 className="mb-2 text-2xl font-bold">新規ピアノリサイタル作成</h1>
      )}
      {SERVICE_TYPE === 'show' && (
        <h1 className="mb-2 text-2xl font-bold">新規リサイタル作成</h1>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-2">
          <label className="inline-block w-28">名前</label>
          <input
            className="rounded-sm border border-orange-400 p-1 shadow"
            {...register('pet_name', { required: true })}
          />
        </div>
        {eventTypes.length > 1 && (
          <div className="my-2">
            {eventTypes.map(({ id, name }) => {
              return (
                <label key={id}>
                  <input
                    type="radio"
                    value={id}
                    {...register('event_type', { required: true })}
                  />
                  {name}
                </label>
              );
            })}
          </div>
        )}
        <div>
          <button
            className="rounded-sm border border-orange-800 bg-orange-800 px-4 py-1 text-white shadow"
            type="submit"
          >
            保存
          </button>
          <button
            className="ml-2 rounded-sm border border-orange-800 px-4 py-1 text-orange-800 shadow"
            type="button"
            onClick={() => {
              onClose?.();
            }}
          >
            キャンセル
          </button>
        </div>
      </form>
    </Modal>
  );
};
