import React from 'react';
import { Link } from 'react-router-dom';

import { AdminEventRow } from './AdminEventRow';
import { useAdminEventList } from './hooks';

export const AdminEventList: React.FC = () => {
  const { events } = useAdminEventList();

  return (
    <div className="container mx-auto p-4">
      <h1 className="mb-4 text-2xl font-bold">イベント一覧</h1>
      <div className="my-2">
        <Link to="/manager" className="text-blue-500 hover:border-b">
          &lt; ホーム
        </Link>
      </div>
      {events?.map((e) => (
        <AdminEventRow key={e.id} event={e} />
      ))}
    </div>
  );
};
