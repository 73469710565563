type Props = {
  children: React.ReactNode;
};

export const Modal: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalFullScreen: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-10 overflow-y-hidden">
        <div className="flex h-full min-h-full items-end justify-center p-4 text-center">
          <div className="relative h-full w-full transform overflow-hidden rounded-lg bg-white p-4  text-left shadow-xl">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
