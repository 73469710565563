import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import useSWR, { useSWRConfig } from 'swr';

import { api } from '../../api';
import { BankInfo } from '../../types';
import { LOGIN_URL } from '../../url';
import { Loading } from '../Loading';

const fetcher = (url: string) => api(url).then((res) => res.data);

// ゆうちょ銀行の場合は支店名がなく、かわりに「記号」がある
// cf. https://www.jp-bank.japanpost.jp/kojin/sokin/koza/kj_sk_kz_furikomi_ksk.html
// 変換サイトで「店番」に変換することができる。
// https://www.jp-bank.japanpost.jp/kojin/sokin/furikomi/kouza/kj_sk_fm_kz_1.html

export const BankInfoPage: React.FC = () => {
  const { data, error } = useSWR<BankInfo>('/me/bank/', fetcher);
  const { mutate } = useSWRConfig();
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  type Inputs = {
    bank_name: string;
    bank_branch_name: string;
    bank_account_type: string;
    bank_account_number: string;
    bank_account_holder_sei: string;
    bank_account_holder_mei: string;
  };

  const defaultValues: Inputs = useMemo(
    () => ({
      bank_name: data?.bank_name ?? '',
      bank_branch_name: data?.bank_branch_name ?? '',
      bank_account_type: data?.bank_account_type ?? '普通',
      bank_account_number: data?.bank_account_number ?? '',
      bank_account_holder_sei: data?.bank_account_holder_sei ?? '',
      bank_account_holder_mei: data?.bank_account_holder_mei ?? '',
    }),
    [data],
  );

  const onSubmit = async (data: Inputs) => {
    setSaved(false);
    setSaving(true);
    await api.patch(`/me/bank/`, data);
    mutate('/me/bank/');
    mutate('/me/status/');
    navigate('/manager/');
    setSaving(false);
    setSaved(true);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  // redirect if not logged in
  useEffect(() => {
    if (!error) return;
    if (error.response?.status === 401 || error.response?.status === 403) {
      window.location.href = LOGIN_URL;
      return;
    }
  }, [error]);

  return (
    <div className="container p-4">
      <h2 className="mb-4 text-xl font-bold">口座番号設定</h2>
      <p>イベントの出演料を振り込むための口座番号を登録してください。</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-2 flex items-center">
          <span className="mr-2 w-20">金融機関名</span>
          <input
            className="rounded border border-orange-400 p-1 shadow"
            {...register('bank_name', { required: true })}
            placeholder="○○銀行"
          />
        </div>
        {errors.bank_name && (
          <div className="text-sm text-red-500">
            金融機関名を入力してください
          </div>
        )}
        <div className="mb-1 mt-2 flex items-center">
          <span className="mr-2 w-20">支店名</span>
          <input
            className="rounded border border-orange-400 p-1 shadow"
            {...register('bank_branch_name', { required: true })}
            placeholder="○○支店"
          />
        </div>
        <div className="text-sm">
          ゆうちょ銀行の場合は支店名のかわりに「記号」を入力してください。
        </div>
        <div className="text-sm">
          ※記号と番号の間に1桁の数字がある場合は、その1桁の数字は使用しません。
        </div>
        {errors.bank_branch_name && (
          <div className="text-sm text-red-500">支店名を入力してください</div>
        )}
        <div className="my-2 flex items-center">
          <span className="mr-2 w-20">口座種別</span>
          <select
            {...register('bank_account_type', { required: true })}
            className="rounded border border-orange-400 p-1 shadow"
          >
            <option value="普通">普通</option>
            <option value="当座">当座</option>
            <option value="貯蓄">貯蓄</option>
          </select>
        </div>
        {errors.bank_account_type && (
          <div className="text-sm text-red-500">口座種別を選択してください</div>
        )}
        <div className="mb-1 mt-2 flex items-center">
          <span className="mr-2 w-20">口座番号</span>
          <input
            className="rounded border border-orange-400 p-1 shadow"
            {...register('bank_account_number', {
              required: true,
              pattern: /^[0-9]*$/,
            })}
            placeholder="0000000"
          />
        </div>
        <div className="text-sm">
          ゆうちょ銀行の場合は「番号」を入力してください。
        </div>
        {errors.bank_account_number?.type === 'required' && (
          <div className="text-sm text-red-500">口座番号を入力してください</div>
        )}
        {errors.bank_account_number?.type === 'pattern' && (
          <div className="text-sm text-red-500">数字のみで入力してください</div>
        )}
        <div className="my-2">
          <div className="my-1">口座名義人(カタカナ)</div>
          <div className="flex">
            <input
              className="mr-2 min-w-0 rounded border border-orange-400 p-1 shadow"
              {...register('bank_account_holder_sei', {
                required: true,
                pattern: /^[ァ-ヶー]*$/,
              })}
              placeholder="ヤマダ"
            />
            <input
              className="min-w-0 rounded border border-orange-400 p-1 shadow"
              {...register('bank_account_holder_mei', {
                required: true,
                pattern: /^[ァ-ヶー]*$/,
              })}
              placeholder="タロウ"
            />
          </div>
        </div>
        {errors.bank_account_holder_sei?.type === 'required' && (
          <div className="text-sm text-red-500">姓を入力してください</div>
        )}
        {errors.bank_account_holder_sei?.type === 'pattern' && (
          <div className="text-sm text-red-500">
            姓をカタカナで入力してください
          </div>
        )}
        {errors.bank_account_holder_mei?.type === 'required' && (
          <div className="text-sm text-red-500">名を入力してください</div>
        )}
        {errors.bank_account_holder_mei?.type === 'pattern' && (
          <div className="text-sm text-red-500">
            名をカタカナで入力してください
          </div>
        )}

        <div className="my-2">
          <button
            className="rounded border border-orange-800 bg-orange-800 px-4 py-1 text-white shadow"
            type="submit"
          >
            {saving && <Loading className="mr-2 inline h-4 w-4" />}
            保存
          </button>
        </div>

        {saved && <div className="text-sm text-green-500">保存しました</div>}
      </form>
    </div>
  );
};
