import React from 'react';

import { SERVICE_TYPE } from '../SERVICE_TYPE';
import { Ceremony } from '../types';

export const ParticipantAnnouncePre: React.FC<{ ceremony: Ceremony }> = ({
  ceremony,
}) => <ParticipantAnnounce ceremony={ceremony} variant="pre" />;

export const ParticipantAnnouncePost: React.FC<{ ceremony: Ceremony }> = ({
  ceremony,
}) => <ParticipantAnnounce ceremony={ceremony} variant="post" />;

export const ParticipantAnnounce: React.FC<{
  ceremony: Ceremony;
  variant: 'pre' | 'post';
}> = ({ ceremony, variant }) => {
  const announce =
    variant === 'pre' ? ceremony.announce_pre : ceremony.announce_post;

  return (
    <div className="flex h-full flex-col">
      <div
        className="m-1 flex-1 overflow-y-auto border border-gray-600 bg-white/50 p-2"
        style={{ minWidth: 200 }}
      >
        <p className="mb-1 font-bold">管理者からのお知らせ</p>
        <p className="my-1 text-sm">
          {variant === 'pre' && <AdminAnnouncePre ceremony={ceremony} />}
          {variant === 'post' && <AdminAnnouncePost ceremony={ceremony} />}
        </p>
      </div>
      {announce && (
        <div
          className="mx-1 flex-1 overflow-y-auto border border-gray-700 bg-white/50 p-2"
          style={{ minWidth: 200 }}
        >
          <p className="my-1 font-bold">主催者からのお知らせ</p>
          <p className="my-1 whitespace-pre-wrap text-sm">{announce}</p>
        </div>
      )}
    </div>
  );
};

const AdminAnnouncePre: React.FC<{ ceremony: Ceremony }> = ({ ceremony }) => {
  if (SERVICE_TYPE === 'pet') {
    return (
      <>
        本日は{ceremony.event_name}
        にご参加いただき誠にありがとうございます。 開演までのお時間を利用して
        {ceremony.pet_name}
        へプレゼント(デジタルコンテンツ)をいただければ幸いです。
      </>
    );
  }
  return (
    <>
      本日は{ceremony.pet_name}
      様のリサイタルをご視聴いただき誠にありがとうございます。
      開演までのお時間を利用して
      {ceremony.pet_name}
      様へプレゼント(デジタルコンテンツ)をいただければ幸いです。
    </>
  );
};

const AdminAnnouncePost: React.FC<{ ceremony: Ceremony }> = ({ ceremony }) => {
  if (SERVICE_TYPE === 'pet') {
    return (
      <>
        本日は{ceremony.event_name}
        にご参加いただき誠にありがとうございます。
        今から１０分間はプレゼント贈呈がまだ可能ですので、もし追加で
        {ceremony.pet_name}にプレゼント(デジタルコンテンツ)を
        いただければ誠に幸いです。
      </>
    );
  }
  return (
    <>
      本日は{ceremony.pet_name}
      様のリサイタルをご視聴いただき、ありがとうございました。
      今から１０分間はプレゼント贈呈がまだ可能ですので、もし追加で
      {ceremony.pet_name}
      様にプレゼント(デジタルコンテンツ)を いただければ誠に幸いです。
    </>
  );
};
