import { ReactElement } from 'react';

import { format, parseISO } from 'date-fns';

import { Ceremony } from '../types';

export const CeremonyParticipantInfo = (props: {
  ceremony: Ceremony;
}): ReactElement => {
  return (
    <div className="mx-auto p-4" style={{ maxWidth: 400 }}>
      <div className="mb-1 text-center text-3xl font-bold">
        {props.ceremony.event_name}
      </div>
      <div className="my-4 text-center">
        {props.ceremony.starts_at &&
          format(parseISO(props.ceremony.starts_at), 'yyyy/MM/dd HH:mm')}
        ~
      </div>
      <div className="my-4 text-center">
        イベントにご参加ありがとうございます。
        <br />
        当日になりましたら、
        <br />
        こちらのページでイベントに参加できます。
        <br />
        開始日時の30分前に開場し、
        <br />
        ご案内画面が現れます
      </div>
    </div>
  );
};
