import React from 'react';
import { FaWrench } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { AdminEvent } from '../types';

const items = [
  { key: 'setup', name: '準備' },
  { key: 'payment', name: '支払' },
  { key: 'invite', name: '招待' },
  { key: 'realtime', name: '当日' },
  { key: 'after', name: '終了' },
] as const;

type NavigationItem = (typeof items)[number]['key'];

type Props = {
  active: NavigationItem;
  event: AdminEvent;
};

export const AdminEventHeader: React.FC<Props> = ({ active, event }) => {
  return (
    <>
      <h1 className="mb-2 text-2xl font-bold flex items-center">
        <FaWrench className="mr-1" />
        {event.event_name}
      </h1>
      <Link
        to="/manager"
        className="border-blue-700 text-blue-700 hover:border-b"
      >
        &lt; ホーム
      </Link>
      <div className="hidden justify-center p-3 sm:flex">
        {items.map((item, i) => {
          const isActive = item.key === active;
          return (
            <React.Fragment key={item.key}>
              {i !== 0 && (
                <div className="mx-2 flex items-center justify-center">
                  &gt;
                </div>
              )}
              {isActive && (
                <div className="mx-2 flex h-24 w-24 items-center justify-center rounded-full border-2 border-orange-300 bg-orange-300">
                  <span>{item.name}</span>
                </div>
              )}
              {!isActive && (
                <Link
                  to={`/manager/event/${event.id}/${item.key}/`}
                  className="mx-2 flex h-24 w-24 items-center justify-center rounded-full border-2 border-orange-300 transition-colors duration-300 ease-in-out hover:bg-orange-100"
                >
                  <span>{item.name}</span>
                </Link>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <div className="flex justify-center p-3 sm:hidden">
        {items.map((item, i) => {
          const isActive = item.key === active;
          return (
            <React.Fragment key={item.key}>
              {isActive && (
                <div className="flex h-12 w-24 items-center justify-center bg-orange-300">
                  <span>{item.name}</span>
                </div>
              )}
              {!isActive && (
                <Link
                  to={`/manager/event/${event.id}/${item.key}/`}
                  className="flex h-12 w-24 items-center justify-center bg-orange-100 transition-colors duration-300 ease-in-out hover:bg-orange-100"
                >
                  <span>{item.name}</span>
                </Link>
              )}
              {i !== items.length - 1 && (
                <div
                  className={clsx(
                    'flex h-12 w-8 items-center justify-center',
                    isActive ? 'bg-orange-300' : 'bg-orange-100',
                  )}
                  style={{ clipPath: 'polygon(0 0, 0% 100%, 100% 50%)' }}
                ></div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};
