import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import { format, parseISO } from 'date-fns';

import { FormRowInput } from '../Form';
import { SERVICE_TYPE } from '../SERVICE_TYPE';
import { Ceremony } from '../types';

export const CeremonyParticipantJoin = (props: {
  ceremony: Ceremony;
  submitNames: (realname: string, nickname: string, email?: string) => void;
}): ReactElement => {
  type Inputs = {
    realname: string;
    nickname: string;
    email: string;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    props.submitNames(data.realname, data.nickname, data.email);
  };

  return (
    <div className="mx-auto p-4" style={{ maxWidth: 400 }}>
      <div className="my-1 text-center">イベントに招待されています。</div>
      <div className="mb-1 text-center text-3xl font-bold">
        {props.ceremony.event_name}
      </div>
      <div className="m-1 text-center">
        {props.ceremony.starts_at &&
          format(parseISO(props.ceremony.starts_at), 'yyyy/MM/dd HH:mm')}
        ~
      </div>
      <div className="my-2 border border-gray-400 p-2 shadow">
        <div>イベントに参加するには、以下のフォームに入力してください。</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormRowInput
            label="名前"
            message="※名前はイベントの主催者にのみ公開されます"
            required
            error={errors && errors.realname && '名前を入力してください'}
            {...register('realname', { required: true })}
          />

          <FormRowInput
            label="ニックネーム"
            message="※ニックネームはイベントの参加者全員に公開されます"
            required
            error={
              errors && errors.nickname && 'ニックネームを入力してください'
            }
            {...register('nickname', { required: true })}
          />

          <FormRowInput
            label="メールアドレス"
            message="※メールアドレスはイベントの主催者にのみ公開されます"
            {...register('email', {
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: '有効なメールアドレスを入力してください',
              },
            })}
            error={errors && errors.email && errors.email.message}
          />

          <div className="text-xs text-gray-500">
            メールアドレスを入力していただいた方には、イベント終了後に
            {SERVICE_TYPE === 'piano' ? '主催ピアニスト' : '主催者'}
            よりお礼や特別プレゼント動画をお届けします。
          </div>
          <button
            className="my-2 block w-full rounded-sm bg-orange-800 px-4 py-1 text-white shadow"
            type="submit"
          >
            参加する
          </button>
        </form>
      </div>
      <div className="my-2 block w-full rounded border border-red-300 bg-red-100 px-4 py-1 text-center shadow">
        <p>
          動作ブラウザは、Androidの場合はGoogle Chrome,
          iPhoneの場合はSafariを推奨しています。
          推奨環境以外のブラウザでの動作は保証しておりません。
        </p>
        <p>PCの場合はGoogle Chromeの最新版を推奨しています。</p>
      </div>
    </div>
  );
};
