import React, { useRef } from 'react';
import { FaPlay } from 'react-icons/fa';

import useSWR from 'swr';

import { ModalPortal } from '../ModalPortal';
import { useFrames } from '../frames';
import { Ceremony, CeremonyMedia } from '../types';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export const Video: React.FC<{
  ceremony: Ceremony;
  media: CeremonyMedia;
}> = (props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const frames = useFrames();

  const variant =
    props.ceremony.event_status === 'running_after' ? 'post' : 'pre';
  const muted = {
    pre: props.ceremony.pre_video_muted,
    post: props.ceremony.post_video_muted,
  }[variant];
  const mediaType = {
    pre: props.ceremony.ceremony_image_type,
    post: props.ceremony.post_media_type,
  }[variant];
  const currentImage = {
    pre: props.media.ceremony_image,
    post: props.media.post_image,
  }[variant];
  const { data: video } = useSWR<{ url: string }>(
    `/api/event-video/get-link/${props.ceremony.token}/${variant}/`,
    fetcher,
  );
  const videoUrl = video?.url;

  if (frames == null) {
    return null;
  }
  const frame_url = frames.find(
    (f) => f.id === props.ceremony.ceremony_frame,
  )?.url;

  if (mediaType === 'none') return null;
  if (mediaType === 'image') {
    if (!currentImage) {
      console.error('No image url provided');
      return null;
    }
    return (
      <div
        style={{
          position: 'relative',
          width: '80%',
          aspectRatio: 1,
          margin: '0 auto',
          display: 'block',
        }}
      >
        <img
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: 'block',
          }}
          src={currentImage}
          alt="pet"
          crossOrigin="anonymous"
        />
        {frame_url && (
          <img
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              display: 'block',
            }}
            src={frame_url.full}
            alt=""
            crossOrigin="anonymous"
          />
        )}
      </div>
    );
  }
  if (!videoUrl) {
    console.error('No video url provided');
    return null;
  }

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        margin: '0 auto',
        display: 'block',
      }}
    >
      <video
        autoPlay
        muted={muted}
        loop
        playsInline
        ref={videoRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          display: 'block',
        }}
        crossOrigin="anonymous"
        onPlay={() => {
          setIsPlaying(true);
        }}
        onPause={() => {
          setIsPlaying(false);
        }}
      >
        <source src={videoUrl} type="video/mp4" />
        <p>Your browser doesn't support HTML5 video.</p>
      </video>
      {frame_url && (
        <img
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: 'block',
          }}
          src={frame_url.full}
          alt=""
          crossOrigin="anonymous"
        />
      )}
      {!isPlaying && (
        <ModalPortal>
          <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
            <div className="pointer-events-auto flex flex-col items-center">
              <div className="pointer-events-none relative mb-3 whitespace-nowrap rounded bg-orange-300 px-2 py-2 text-black transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-8 before:border-transparent before:border-t-orange-300 before:content-['']">
                ボタンを押して音声を再生
              </div>
              <button
                className="white flex rounded bg-blue-500 p-1 text-white items-center"
                onClick={() => {
                  videoRef.current?.play();
                }}
              >
                <FaPlay className="mr-2" />
                動画を再生
              </button>
            </div>
          </div>
        </ModalPortal>
      )}
    </div>
  );
};
