import AgoraRTC, { IAgoraRTCClient } from 'agora-rtc-sdk-ng';

import { uid } from './useAgoraSend';

// localAudioTrack and localVideoTrack が1つしかないと仮定している

// global cache
let _engine: IAgoraRTCClient | null = null;
export const useAgoraEngine = (
  tokenFunc: (uid: number) => Promise<string>,
  handleTokenExpired: () => void,
): IAgoraRTCClient => {
  if (!_engine) {
    _engine = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
    _engine.on('token-privilege-will-expire', async () => {
      if (!_engine) return;
      const token = await tokenFunc(uid);
      await _engine.renewToken(token);
      console.log('Successfully renewed agora token!');
    });
    _engine.on('token-privilege-did-expire', async () => {
      if (!_engine) return;
      handleTokenExpired();
      console.log('handleTokenExpired');
    });
  }
  return _engine;
};
