import React, { useEffect, useState } from 'react';
import { FaMicrophone, FaPlay } from 'react-icons/fa';
import { MdCameraswitch } from 'react-icons/md';

import { Alert } from '../admin/Alert';
import { Modal } from '../admin/Modal';
import { api } from '../api';
import { useAgoraSend, useAudioIsActive } from '../hooks/agora/useAgoraSend';
import image2 from '../img/test-virtual/forest.jpeg';
import image3 from '../img/test-virtual/mountain.jpeg';
import image1 from '../img/test-virtual/universe.jpeg';

type Props = {
  canStartEvent: boolean;
  isEventStarted: boolean;
  channel: string;
  eventStartHandler: () => Promise<void>;
  onStartCountdown: boolean;
};

const virtualImages = [image1, image2, image3];

export const LiveSend: React.FC<Props> = (props) => {
  const getToken = async (uid: number) => {
    const body = { uid };
    console.log({ body });
    const res = await api.post<{ token: string }>(
      `/manager/ceremony/${props.channel}/live/`,
      { uid },
    );
    const { token } = res.data;
    return token;
  };
  const [showVBModal, setShowVBModal] = useState(false);
  const [isGreenback, setGreenback] = useState(false);
  const handleChange = () => {
    setGreenback(!isGreenback);
    console.log(isGreenback);
  };

  const {
    divRef,
    cameraOn,
    startLive,
    stopLive,
    activeCameraDeviceId,
    switchCameraNext,
    switchCameraTo,
    switchMicrophoneTo,
    cameraList,
    activeMicrophoneDeviceId,
    microphoneList,
    setVirtualImage,
    virtualImageOff,
    setGreenbackImage,
    greenbackImageOff,
    permissionDenied,
    supported,
    joined,
    joining,
    leaving,
    videoStarted,
    cameraOnProgress,
  } = useAgoraSend(props.channel, getToken);

  const audioIsActive = useAudioIsActive();

  useEffect(() => {
    if (!supported) {
      console.error('not supported');
    }
  }, [supported]);

  // TODO: 開始ボタンの2回クリックを防ぐ

  const LiveOptionControl: React.FC = () => {
    return (
      <>
        <div className="flex">
          <button
            id="openButton"
            type="button"
            className="mx-2 flex items-center rounded bg-blue-500 px-2 py-1 text-white"
            onClick={() => {
              setShowVBModal(true);
            }}
          >
            バーチャル背景
          </button>
        </div>
        {cameraList.length === 2 && (
          <div className="mt-2 flex">
            <button
              type="button"
              onClick={switchCameraNext}
              className="flex items-center rounded bg-gray-500 px-2 py-1 text-sm text-white"
            >
              <MdCameraswitch className="text-lg mr-2" />
              <span>カメラを切り替える</span>
            </button>
          </div>
        )}
        {cameraList.length > 2 && (
          <div className="mt-2 flex items-center">
            <MdCameraswitch />
            <select
              className="ml-2 flex-1 rounded border border-orange-500 shadow"
              onChange={(e) => {
                switchCameraTo(e.currentTarget.value);
              }}
              value={activeCameraDeviceId || ''}
            >
              {cameraList.map((c) => (
                <option value={c.deviceId}>{c.label}</option>
              ))}
            </select>
          </div>
        )}
        {microphoneList.length > 1 && (
          <div className="mt-2 flex items-center">
            <FaMicrophone />
            <select
              className="ml-2 flex-1 rounded border border-orange-500 shadow"
              onChange={(e) => {
                switchMicrophoneTo(e.currentTarget.value);
              }}
              value={activeMicrophoneDeviceId || ''}
            >
              {microphoneList.map((m) => (
                <option value={m.deviceId}>{m.label}</option>
              ))}
            </select>
          </div>
        )}
        {!audioIsActive && (
          <Alert variant="error">
            マイクの音声が検出されませんでした。マイク本体でミュートさていないか、確認してください。
            <br />
            仮想マイクを使用している場合は、音声の配信を開始してください。
          </Alert>
        )}
      </>
    );
  };

  return (
    <div>
      {permissionDenied && <PermissionDeniedAlert />}
      {!videoStarted && (
        <button
          type="button"
          onClick={cameraOn}
          className="m-2 flex items-center rounded bg-blue-500 px-4 py-2 text-white"
        >
          {cameraOnProgress && (
            <div className="ml-2 inline-block h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent" />
          )}
          <span>カメラをオンにする</span>
        </button>
      )}
      {videoStarted &&
        props.canStartEvent &&
        !joined &&
        !props.onStartCountdown && (
          <Alert variant="error">
            あなたの姿はまだ配信されていません。
            <br />
            「配信を開始」を押してください。
          </Alert>
        )}
      {videoStarted && !joined && (
        <>
          {props.canStartEvent && (
            <div className="flex">
              <button
                type="button"
                onClick={async () => {
                  if (!props.isEventStarted) {
                    if (
                      !window.confirm(
                        '15秒後に配信を開始します。イベントを開始しますか？',
                      )
                    ) {
                      return;
                    }
                    await props.eventStartHandler();
                  }
                  startLive();
                }}
                disabled={joining}
                className="mx-2 flex items-center rounded bg-green-700 px-2 py-1 text-sm text-white"
              >
                <FaPlay className="mr-2" />
                <span>配信を開始</span>
                {joining && (
                  <div className="ml-2 inline-block h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent" />
                )}
              </button>
            </div>
          )}
          <div className="mt-2">
            <LiveOptionControl />
          </div>
        </>
      )}
      {joined && <Alert variant="success">配信中です。</Alert>}
      {joined && (
        <>
          <div className="flex">
            <button
              type="button"
              onClick={stopLive}
              disabled={leaving}
              className="flex items-center rounded bg-red-700 px-2 py-1 text-sm text-white"
            >
              配信を中断
              <br />
              （紹介画像/動画を再生）
            </button>
          </div>
          <div className="mt-2">
            <LiveOptionControl />
          </div>
        </>
      )}
      <div
        ref={divRef}
        style={{
          width: '100%',
          maxWidth: 300,
          aspectRatio: videoStarted ? 1 : 0,
          padding: '15px 5px 5px 5px',
        }}
      />
      {showVBModal && (
        <BackgroundSelectModal
          onBackgroundImageSelect={(image) => {
            isGreenback ? setGreenbackImage(image) : setVirtualImage(image);
          }}
          onNoBackgroundImageSelect={() =>
            isGreenback ? greenbackImageOff : virtualImageOff()
          }
          onGreenback={() => handleChange()}
          onClose={() => setShowVBModal(false)}
          isGreenback={isGreenback}
        />
      )}
    </div>
  );
};

const PermissionDeniedAlert: React.FC = () => (
  <Alert variant="error">
    カメラまたはマイクの使用が許可されていません。ライブ配信を行うには、端末の設定・ブラウザの設定から許可してください。
    <br />
    許可設定を変更した後は、ページを再読み込みしてください。
    <br />
    設定をしてもこのメッセージが表示される場合は、別のブラウザをお試しください。
  </Alert>
);

const BackgroundSelectModal: React.FC<{
  onClose: () => void;
  onBackgroundImageSelect: (image: string) => void;
  onNoBackgroundImageSelect: () => void;
  onGreenback: () => void;
  isGreenback: boolean;
}> = (props) => (
  <Modal>
    <h2 className="text-center text-xl font-bold">バーチャル背景を設定</h2>
    <div className="flex flex-wrap justify-between">
      <button
        type="button"
        style={{ width: 150, height: 150 }}
        className="m-1 border border-gray-500 p-1"
        onClick={() => props.onNoBackgroundImageSelect()}
      >
        <span>なし</span>
      </button>
      {virtualImages.map((image) => (
        <button
          type="button"
          style={{ width: 150, height: 150 }}
          className="m-1 border border-gray-500 p-1"
          onClick={() => props.onBackgroundImageSelect(image)}
          key={image}
        >
          <img src={image} alt="virtual" />
        </button>
      ))}
    </div>
    <div className="flex justify-center">
      <button
        className="m-2 rounded bg-blue-500 px-4 py-2 text-white"
        onClick={() => props.onClose()}
      >
        OK
      </button>
      <div>
        <input
          type="checkbox"
          onChange={props.onGreenback}
          defaultChecked={props.isGreenback}
        />
        グリーンバックを使用
      </div>
    </div>
  </Modal>
);
