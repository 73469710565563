import { useGifts } from './gift';
import { Gift } from './types';

export const GiftIcon: React.FC<{
  gift: Pick<Gift, 'amount' | 'item'> | Gift;
  className?: string;
}> = ({ gift, className }) => {
  const GIFT_TYPES = useGifts();
  if (!GIFT_TYPES) return null;
  const giftType = GIFT_TYPES.find((g) => g.type === gift.item);
  const image = giftType?.image;
  const size =
    {
      10000: 1,
      5000: 0.9,
      3000: 0.8,
      2000: 0.75,
      1000: 0.7,
      500: 0.6,
    }[giftType?.amount ?? 500] ?? 1;

  const mergedClassName = className?.includes('h-')
    ? className
    : `h-6 ${className ?? ''}`;

  return (
    <img
      src={image}
      alt={gift.item}
      className={mergedClassName}
      style={{ transform: `scale(${size})` }}
    />
  );
};
