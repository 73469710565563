import { useState } from 'react';
import { MdSend } from 'react-icons/md';
import { useParams } from 'react-router-dom';

import { useSWRConfig } from 'swr';

import { api } from '../api';
import { Ceremony } from '../types';

export const ChatSend = (props: {
  ceremony: Ceremony;
  participantName: string;
}) => {
  const { token } = useParams<{ token: string }>();
  const [message, setMessage] = useState('');
  const { mutate } = useSWRConfig();

  const sendMessage = async (message: string) => {
    try {
      await api.post(`/ceremony/${token}/message/`, {
        name: props.participantName,
        message: message,
      });
      mutate(`/api/ceremony/${token}/`);
    } catch (e) {
      window.alert('エラーが発生しました。');
      console.error(e);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (message.length > 0) {
      sendMessage(message);
      setMessage('');
    }
  };

  return (
    <form className="flex px-4 py-2 text-live-ui" onSubmit={onSubmit}>
      <input
        className="flex-1 rounded-xl border border-live-ui bg-white px-2 py-1 outline-black"
        placeholder="コメントを入力"
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      />
      <button
        type="submit"
        className="mx-2 rounded-full p-1"
        onClick={() => {}}
      >
        <MdSend className="text-2xl" />
      </button>
    </form>
  );
};
