import React, { useEffect, useMemo } from 'react';
import { FaCheckCircle, FaGift, FaUser } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

import { formatDistance, parseISO } from 'date-fns';
import { ja as jaLocale } from 'date-fns/locale';
import useSWR from 'swr';

import { GiftIcon } from '../GiftIcon';
import { api } from '../api';
import { Table, TableCell, TableHeader, TableRow } from '../components/Table';
import { AdminEvent } from '../types';
import { LOGIN_URL } from '../url';
import { AdminEventHeader } from './AdminEventHeader';
import { Alert } from './Alert';

const fetcher = (url: string) => api(url).then((res) => res.data);

export const AdminEventAfter: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: event, error } = useSWR<AdminEvent>(
    `/manager/ceremony/${id}/`,
    fetcher,
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (event && event.event_status === 'running') {
      navigate(`/manager/event/${event.id}/realtime/`);
    }
    if (event && event.event_status === 'not_started') {
      if (event.is_payment_completed) {
        navigate(`/manager/event/${event.id}/invite/`);
      } else {
        navigate(`/manager/event/${event.id}/setup/`);
      }
    }
  }, [event, navigate]);

  if (error) {
    if (error.response?.status === 401) {
      window.location.href = LOGIN_URL;
      return null;
    }
    if (error.response?.status === 403) {
      return <div>権限がありません</div>;
    }
    return <div>エラーが発生しました</div>;
  }
  if (!event) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4">
      <AdminEventHeader active="after" event={event} />
      <div className="mx-auto max-w-screen-md text-center">
        <h2 className="mb-2 mt-4 text-2xl font-bold">イベント終了</h2>
        <SummaryTable event={event} />
        <h2 className="mb-2 mt-4 text-xl font-bold flex items-center justify-center">
          <FaGift className="mr-1" />
          プレゼント一覧
        </h2>
        <EventGiftTable event={event} />
        <h2 className="mb-2 mt-4 text-xl font-bold flex items-center justify-center">
          <FaUser className="mr-1" />
          参加者一覧
        </h2>
        <ParticipantTable event={event} />
      </div>
    </div>
  );
};

/**
 * return duration string like "10分30秒"
 */
const getDuration = (event: AdminEvent): string | null => {
  if (!event.started_at || !event.finished_at) {
    return null;
  }

  const duration = formatDistance(
    parseISO(event.finished_at),
    parseISO(event.started_at),
    {
      locale: jaLocale,
    },
  );
  return duration;
};

const SummaryTable: React.FC<{ event: AdminEvent }> = ({ event }) => {
  const duration = getDuration(event);
  const participants = event.participants.length;
  const giftTotal = event.gifts.reduce((acc, gift) => acc + gift.amount, 0);

  return (
    <Alert variant="success">
      <div className="flex items-center justify-center my-2">
        <FaCheckCircle className="text-4xl text-green-500" />
      </div>
      <p>
        イベント開催、お疲れ様でした！
        {duration && (
          <>
            <span className="font-bold">{duration}</span>
            の間、イベントを開催しました。
          </>
        )}
      </p>
      <p>
        {participants > 0 && (
          <>
            <span className="font-bold">{participants}人</span>が参加し
            {giftTotal > 0 && (
              <>
                、 <span className="font-bold">{giftTotal}円</span>
                のプレゼントが集まり
              </>
            )}
            ました。
          </>
        )}
      </p>
    </Alert>
  );
};

const EventGiftTable: React.FC<{ event: AdminEvent }> = ({ event }) => {
  const gifts = useMemo(
    () =>
      event.gifts.map((gift) => ({
        ...gift,
        realname: event.participants.find((p) => p.name === gift.name)
          ?.realname,
        email: event.participants.find((p) => p.name === gift.name)?.email,
      })),
    [event],
  ).sort((a, b) => b.amount - a.amount);

  if (gifts.length === 0) {
    return <div>プレゼントはありません</div>;
  }

  return (
    <>
      <Table className="mx-auto my-2">
        <TableRow>
          <TableHeader>名前</TableHeader>
          <TableHeader>メールアドレス</TableHeader>
          <TableHeader>アイテム</TableHeader>
        </TableRow>
        {gifts.map((gift) => (
          <TableRow>
            <TableCell>
              {gift.name} ({gift.realname})
            </TableCell>
            <TableCell>{gift.email || '-'}</TableCell>
            <TableCell>
              <div className="flex">
                <GiftIcon gift={gift} /> {gift.amount}円
              </div>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </>
  );
};

const ParticipantTable: React.FC<{ event: AdminEvent }> = ({ event }) => {
  const participants = event.participants
    .map((p) => ({
      ...p,
      giftPrice: event.gifts
        .filter((g) => g.name === p.name)
        .reduce((a, b) => a + b.amount, 0),
    }))
    .sort((a, b) => b.giftPrice - a.giftPrice);
  if (participants.length === 0) {
    return <div>参加者はいません</div>;
  }

  return (
    <>
      <Table className="mx-auto my-2">
        <TableRow>
          <TableHeader>名前</TableHeader>
          <TableHeader>メールアドレス</TableHeader>
          <TableHeader>プレゼント</TableHeader>
        </TableRow>
        {participants.map((participant) => (
          <TableRow>
            <TableCell>
              {participant.name} ({participant.realname})
            </TableCell>
            <TableCell>{participant.email || '-'}</TableCell>
            <TableCell>
              {participant.giftPrice > 0 ? participant.giftPrice + '円' : '-'}
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </>
  );
};
