import {
  Extension,
  IProcessorContext,
  VideoProcessor,
} from 'agora-rte-extension';

class GreenBackExtension extends Extension<GreenBackVideoProcessor> {
  protected _createProcessor(): GreenBackVideoProcessor {
    return new GreenBackVideoProcessor();
  }
}

export default GreenBackExtension;

function rgb2hsl(r: number, g: number, b: number) {
  // convert r,g,b [0,255] range to [0,1]
  const [h, s, l] = _rgb2hsl_raw(r / 255, g / 255, b / 255);
  return [h * 60, s * 100, l * 100];
}

// based on: https://stackoverflow.com/questions/39118528/rgb-to-hsl-conversion
function _rgb2hsl_raw(r: number, g: number, b: number) {
  // see https://en.wikipedia.org/wiki/HSL_and_HSV#Formal_derivation
  // get the min and max of r,g,b
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  // lightness is the average of the largest and smallest color components
  const lum = (max + min) / 2;
  var hue = 0;
  var sat = 0;
  const c = max - min; // chroma
  if (c === 0) {
    // no saturation
    return [0, 0, lum];
  } else {
    sat = c / (1 - Math.abs(2 * lum - 1));
    switch (max) {
      case r:
        hue = ((g - b) / c + 6) % 6;
        break;
      case g:
        hue = (b - r) / c + 2;
        break;
      case b:
        hue = (r - g) / c + 4;
        break;
    }
  }
  return [hue, sat, lum];
}

class GreenBackVideoProcessor extends VideoProcessor {
  name!: string;
  videoElement: HTMLVideoElement = document.createElement(
    'video',
  ) as HTMLVideoElement;
  canvasElement = document.createElement('canvas');
  ctx = this.canvasElement.getContext('2d') as CanvasRenderingContext2D;
  output_canvasElement = document.createElement('canvas');
  output_ctx = this.output_canvasElement.getContext(
    '2d',
  ) as CanvasRenderingContext2D;
  height: number = 0;
  width: number = 0;
  output_height: number = 280;
  output_width: number = 290;
  left_top_corner: number = 0;
  options: { [name: string]: HTMLImageElement } = {};
  backgroundImageData!: ImageData;
  useBackground: boolean = false;

  protected onTrack(inputTrack: MediaStreamTrack, context: IProcessorContext) {
    this.canvasElement.width = this.output_width;
    this.canvasElement.height = this.output_height;
    this.output_canvasElement.width = this.output_width;
    this.output_canvasElement.height = this.output_height;
    this.videoElement.srcObject = new MediaStream([inputTrack]);
    this.videoElement.play();
    this.height =
      this.videoElement.srcObject.getVideoTracks()[0].getSettings().height || 0;
    this.width =
      this.videoElement.srcObject.getVideoTracks()[0].getSettings().width || 0;

    let rate = Math.min(
      this.output_width / this.width,
      this.output_height / this.height,
    );
    let edge_heigh = this.height * rate;
    this.left_top_corner = (this.output_canvasElement.height - edge_heigh) / 2;
    this.ctx.scale(rate, rate);
    this.output_ctx.scale(rate, rate);
    this.doneProcessing();
    this.loop();
  }
  loop() {
    this.ctx.drawImage(this.videoElement, 0, 0);
    const imageData = this.ctx.getImageData(0, 0, this.width, this.height);
    if (this.useBackground) {
      this.process(imageData);
    }
    requestAnimationFrame(() => this.loop());
  }

  enable(): void | Promise<void> {
    this.useBackground = true;
  }

  disable(): void | Promise<void> {
    this.useBackground = false;
  }

  process(imageData: ImageData) {
    for (let i = 0; i < imageData.data.length; i += 4) {
      let r = imageData.data[i];
      let g = imageData.data[i + 1];
      let b = imageData.data[i + 2];
      // Simple chroma keying
      const [h, s, l] = rgb2hsl(r, g, b);
      if (h > 70 && h < 150 && s > 20 && l > 20 && l < 80) {
        // Adjust these values based on your chroma key color
        if (this.backgroundImageData) {
          if (
            imageData.width !== this.backgroundImageData.width ||
            imageData.height !== this.backgroundImageData.height
          ) {
            console.error('The images do not have the same dimensions.');
            return imageData; // Return the original imageData or handle differently
          }
          imageData.data[i] = this.backgroundImageData.data[i]; // Set alpha to 0 to make it transparent
          imageData.data[i + 1] = this.backgroundImageData.data[i + 1];
          imageData.data[i + 2] = this.backgroundImageData.data[i + 2];
          imageData.data[i + 3] = 255;
        }
      }
    }
    this.output_ctx.putImageData(imageData, 0, this.left_top_corner);
  }

  doneProcessing() {
    // Assemble MediaStreamTrack
    const msStream = this.output_canvasElement.captureStream(30);
    const outputTrack = msStream.getVideoTracks()[0];

    // Output the processed video
    if (this.context) {
      this.output(outputTrack, this.context);
      console.log(this.output);
    }
  }

  setOptions(options: { [name: string]: HTMLImageElement | number }) {
    if (typeof options['width'] == 'number') {
      this.output_width = options['width'];
    }
    if (typeof options['height'] == 'number') {
      this.output_height = options['height'];
    }
    if (options['source'] instanceof HTMLImageElement) {
      this.backgroundImageData = this.convertImageToImageData(
        options['source'],
      );
    }
  }

  convertImageToImageData(image: HTMLImageElement) {
    // Create a canvas with the same dimensions as the image
    const canvas = document.createElement('canvas');

    canvas.width = this.width;
    canvas.height = this.height;

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error('Unable to get canvas context');
    }

    // Draw the image onto the canvas
    ctx.drawImage(
      image,
      0,
      0,
      image.width,
      image.height,
      0,
      0,
      canvas.width,
      canvas.height,
    );

    // Extract the ImageData from the same area of the canvas
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    return imageData;
  }
}
