import { useCallback, useEffect, useRef, useState } from 'react';

import AgoraRTC, { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import { AxiosError } from 'axios';

const APP_ID = '090dcb2994404f6aa1d5ba3f40f39c71';

let oldChannel: string | null = null;
const uid = Math.floor(Math.random() * 1000000000);
console.log('Agora uid: ' + uid);
let connecting = false;

let _engine: IAgoraRTCClient | null = null;

export const useAgoraReceive = (
  channel: string,
  tokenFunc: (uid: number) => Promise<string>,
) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [live, setLive] = useState<boolean>(false);
  const [muted, setMuted] = useState<boolean>(false);

  const getEngine = useCallback(async () => {
    if (!_engine) {
      _engine = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
      _engine.on('user-published', async (user, mediaType) => {
        if (!_engine) return;

        if (!divRef.current) {
          throw new Error('divRef.current is null');
        }

        await _engine.subscribe(user, mediaType);
        if (mediaType === 'video') {
          const remoteVideoTrack = user.videoTrack;
          remoteVideoTrack?.play(divRef.current, { fit: 'contain' });
        }
        if (mediaType === 'audio') {
          const remoteAudioTrack = user.audioTrack;
          remoteAudioTrack?.play();
        }
        setLive(true);
      });

      _engine.on('user-unpublished', (user, mediaType) => {
        if (!_engine) return;

        if (mediaType === 'video') {
          const remoteVideoTrack = user.videoTrack;
          remoteVideoTrack?.stop();
        }
        if (mediaType === 'audio') {
          const remoteAudioTrack = user.audioTrack;
          remoteAudioTrack?.stop();
        }
        setLive(false);
      });
      _engine.on('token-privilege-will-expire', async () => {
        if (!_engine) return;
        try {
          const token = await tokenFunc(uid);
          await _engine.renewToken(token);
          console.log('Successfully renewed agora token!');
        } catch (e) {
          if (e instanceof AxiosError && e.code === 'ERR_BAD_REQUEST') {
            // pass
          } else {
            throw e;
          }
        }
      });
      _engine.on('token-privilege-did-expire', async () => {
        if (!_engine) return;
        try {
          const token = await tokenFunc(uid);
          await _engine.join(APP_ID, channel, token, uid);
          console.log('Successfully re-joined to agora');
        } catch (e) {
          if (e instanceof AxiosError && e.code === 'ERR_BAD_REQUEST') {
            // pass
          } else {
            throw e;
          }
        }
      });
    }
    return _engine;
  }, [tokenFunc, channel]);

  useEffect(() => {
    AgoraRTC.onAutoplayFailed = async () => {
      setMuted(true);
      const engine = await getEngine();
      engine.remoteUsers.forEach((user) => {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack?.setVolume(0);
      });
    };
  }, [getEngine]);

  useEffect(() => {
    (async () => {
      if (connecting) return;
      if (oldChannel !== channel) {
        // leave the channel if in the call
        if (oldChannel && _engine) {
          _engine.leave();
        }
        // set the new channel name
        oldChannel = channel;

        connecting = true;

        try {
          const token = await tokenFunc(uid);
          const engine = await getEngine();
          await engine.join(APP_ID, channel, token, uid);
        } catch (e) {
          if (e instanceof AxiosError && e.code === 'ERR_BAD_REQUEST') {
            // pass
          } else {
            throw e;
          }
        }
        connecting = false;
      }
    })();
  }, [channel, tokenFunc, getEngine]);

  const unmute = useCallback(async () => {
    const engine = await getEngine();
    engine.remoteUsers.forEach((user) => {
      const remoteAudioTrack = user.audioTrack;
      remoteAudioTrack?.setVolume(100);
    });

    setMuted(false);
  }, [getEngine]);

  return { divRef, live, muted, unmute };
};
