import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import useSWR from 'swr';

import { CopyableLink } from '../CopyableLink';
import { api } from '../api';
import { AdminEvent } from '../types';
import { LOGIN_URL } from '../url';
import { AdminEventHeader } from './AdminEventHeader';
import { Alert } from './Alert';

const fetcher = (url: string) => api(url).then((res) => res.data);

export const AdminEventInvite: React.FC = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const { data: event, error } = useSWR<AdminEvent>(
    `/manager/ceremony/${id}/`,
    fetcher,
  );

  // If the pamyment is not completed, redirect to payment page
  React.useEffect(() => {
    if (event && !event.is_payment_completed) {
      navigate(`/manager/event/${event.id}/payment/`);
    }
    if (event && event.event_status === 'finished') {
      navigate(`/manager/event/${event.id}/after/`);
    }
    if (event && !event.is_setup_completed) {
      navigate(`/manager/event/${event?.id}/setup/`);
    }
  }, [event, navigate]);

  if (error) {
    if (error.response?.status === 401) {
      window.location.href = LOGIN_URL;
      return null;
    }
    if (error.response?.status === 403) {
      return <div>権限がありません</div>;
    }
    return <div>エラーが発生しました</div>;
  }
  if (!event) {
    return <div>Loading...</div>;
  }

  const inviteLink = `${window.location.origin}/ceremony/${event.token}/`;

  return (
    <div className="p-4">
      <AdminEventHeader active="invite" event={event} />
      <div className="mx-auto max-w-screen-md text-center">
        <h2 className="mb-2 mt-4 text-2xl font-bold">イベント招待</h2>
        <p>準備ができました</p>
        <p>下記のリンクを送って、参加者を招待しましょう。</p>
        <p>※参加者はイベント開始30分前から画面にアクセスできます。</p>
        <CopyableLink link={inviteLink} />
      </div>
      <div className="mx-auto max-w-screen-md text-center">
        <Alert variant="info">
          <div className="p-2">
            <p>
              イベント当日になったら、当日画面からライブ配信を行ってください。
            </p>
          </div>
          <div className="p-2">
            <Link
              to={`/manager/event/${event.id}/realtime/`}
              className="m-2 rounded bg-blue-400 px-4 py-2 text-black shadow"
            >
              イベント当日画面へ
            </Link>
          </div>
        </Alert>
      </div>
    </div>
  );
};
