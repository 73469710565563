import React, { useEffect, useState } from 'react';

import useSWR from 'swr';

import { Loading } from './admin/Loading';
import { ModalFullScreen } from './admin/Modal';
import { api } from './api';

const useHintModal = (hintId: string) => {
  const [show, setShow] = useState(false);
  const [sending, setSending] = useState(false);

  const fetcher = (url: string) => api.get(url).then((res) => res.data);
  const { data, mutate } = useSWR<{ hints: string[] }>(`/hint/`, fetcher);

  useEffect(() => {
    if (data) {
      if (!data.hints.some((hint) => hint === hintId)) {
        setShow(true);
      }
      //setShow(!data.hints.some((hint) => hint === hintId));
    }
  }, [data, hintId]);

  const showManual = () => {
    setShow(true);
  };

  const onClickClose = () => {
    if (sending) return;
    if (data && data.hints.some((hint) => hint === hintId)) {
      setShow(false);
      return;
    }
    setSending(true);
    api.post(`/hint/${hintId}/`);
    mutate();
    setSending(false);
    setShow(false);
  };

  return { show, sending, onClickClose, showManual };
};

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
  className?: string;
  hintId: string;
};
export const HintModalFullScreen: React.FC<Props> = ({
  content,
  className,
  children,
  hintId,
}) => {
  const { show, sending, onClickClose, showManual } = useHintModal(hintId);
  return (
    <>
      <button className={className} onClick={() => showManual()}>
        {children}
      </button>
      {show && (
        <ModalFullScreen>
          <div className="flex h-full flex-col justify-between">
            <div className="hint-modal overflow-x-auto rounded border border-gray-300 p-2">
              {content}
            </div>
            <button
              className="mt-2 flex items-center rounded border border-orange-800 bg-orange-800 px-4 py-1 text-white shadow"
              onClick={onClickClose}
            >
              {sending && <Loading className="mr-2" />}
              閉じる
            </button>
          </div>
        </ModalFullScreen>
      )}
    </>
  );
};
