import clsx from 'clsx';

export const Table: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return <table className={clsx(className)}>{children}</table>;
};

export const TableHead: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return <thead className={clsx(className)}>{children}</thead>;
};

export const TableBody: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return <tbody className={clsx(className)}>{children}</tbody>;
};

export const TableRow: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return <tr className={clsx(className)}>{children}</tr>;
};

export const TableCell: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <td className={clsx('border border-blue-300 p-1', className)}>
      {children}
    </td>
  );
};

export const TableHeader: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <th className={clsx('border border-blue-300 bg-blue-300', className)}>
      {children}
    </th>
  );
};
