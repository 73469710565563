import { useEffect, useMemo } from 'react';

import useSWR from 'swr';

import { api } from '../api';
import { AdminEvent } from '../types';
import { LOGIN_URL } from '../url';

const fetcher = (url: string) => api(url).then((res) => res.data);

export const useAdminEventList = () => {
  const { data, error } = useSWR<AdminEvent[]>('/manager/ceremony/', fetcher);

  useEffect(() => {
    if (!error) return;
    if (error.response?.status === 401 || error.response?.status === 403) {
      window.location.href = LOGIN_URL;
      return;
    }
  }, [error]);
  const events = useMemo(
    () =>
      data
        ?.sort((a, b) => {
          if (a.starts_at && b.starts_at) {
            return a.starts_at.localeCompare(b.starts_at);
          }
          if (a.starts_at) {
            return -1;
          }
          if (b.starts_at) {
            return 1;
          }
          return 0;
        })
        ?.reverse(),
    [data],
  );
  return { events };
};
