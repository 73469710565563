import { useMemo } from 'react';

import useSWR from 'swr';

import { api } from './api';

export type Frame = {
  id: string;
  price: number;
  url: {
    full: string;
    top?: string;
    bottom?: string;
  };
};

const image_map: Partial<
  Record<string, { full: string; top?: string; bottom?: string }>
> = {
  // piano
  piano1: {
    full: require('../src/img/frames/piano_1.png'),
    top: require('../src/img/frames/piano_1_up.png'),
    bottom: require('../src/img/frames/piano_1_low.png'),
  },
  piano2: {
    full: require('../src/img/frames/piano_2.png'),
    top: require('../src/img/frames/piano_2_up.png'),
    bottom: require('../src/img/frames/piano_2_low.png'),
  },
  piano3: {
    full: require('../src/img/frames/piano_3.png'),
    top: require('../src/img/frames/piano_3_up.png'),
    bottom: require('../src/img/frames/piano_3_low.png'),
  },
  piano4: {
    full: require('../src/img/frames/piano_4.png'),
    top: require('../src/img/frames/piano_4_up.png'),
    bottom: require('../src/img/frames/piano_4_low.png'),
  },
  piano5: {
    full: require('../src/img/frames/piano_5.png'),
    top: require('../src/img/frames/piano_5_up.png'),
    bottom: require('../src/img/frames/piano_5_low.png'),
  },
  piano6: {
    full: require('../src/img/frames/piano_6.png'),
    top: require('../src/img/frames/piano_6_up.png'),
    bottom: require('../src/img/frames/piano_6_low.png'),
  },

  // pet & show
  '1': {
    full: require('../src/img/frames/1.png'),
    top: require('../src/img/frames/1_up.png'),
  },
  '2': {
    full: require('../src/img/frames/2.png'),
    top: require('../src/img/frames/2_up.png'),
    bottom: require('../src/img/frames/2_low.png'),
  },
  '3': {
    full: require('../src/img/frames/3.png'),
    top: require('../src/img/frames/3_up.png'),
    bottom: require('../src/img/frames/3_low.png'),
  },
  '4': {
    full: require('../src/img/frames/4.png'),
    bottom: require('../src/img/frames/4_low.png'),
  },
  '5': {
    full: require('../src/img/frames/5.png'),
    top: require('../src/img/frames/5_up.png'),
    bottom: require('../src/img/frames/5_low.png'),
  },
  '6': {
    full: require('../src/img/frames/6.png'),
    top: require('../src/img/frames/6_up.png'),
  },
  '7': {
    full: require('../src/img/frames/7.png'),
    top: require('../src/img/frames/7_up.png'),
    bottom: require('../src/img/frames/7_low.png'),
  },
  '8': {
    full: require('../src/img/frames/8.png'),
    bottom: require('../src/img/frames/8_low.png'),
  },
  '9': {
    full: require('../src/img/frames/9.png'),
    top: require('../src/img/frames/9_up.png'),
    bottom: require('../src/img/frames/9_low.png'),
  },
};

const fetcher = (url: string) => api.get(url).then((res) => res.data);

type APIResponse = {
  frames: {
    id: string;
    price: number;
  }[];
};

export const useFrames = (): Frame[] | null => {
  const { data } = useSWR<APIResponse>('/frames/', fetcher);

  const frames = useMemo(() => {
    if (!data) return null;
    return data.frames.map((frame) => {
      return {
        ...frame,
        url: image_map[frame.id] ?? { full: '', top: '', bottom: '' },
      };
    });
  }, [data]);
  return frames;
};
