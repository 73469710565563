import useSWR from 'swr';

import { api } from './api';

const fetcher = (url: string) => api.get(url).then((res) => res.data);

export const useFlag = (name: string): string | null => {
  const { data } = useSWR<{ value: string }>(`/flags/${name}/`, fetcher);
  return data?.value ?? null;
};
