import { useState } from 'react';
import { FaListUl } from 'react-icons/fa';
import { MdAccountBalance, MdCreate } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { AdminEventCreateModal } from './AdminEventCreateModal';
import { AdminEventFlowHintButton } from './AdminEventFlowHintModal';
import { AdminEventRow } from './AdminEventRow';
import { useAdminEventList } from './hooks';

export const AdminIndex: React.FC = () => {
  const { events } = useAdminEventList();
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  return (
    <div className="container mx-auto p-4">
      <div className="mb-2 flex items-center justify-between">
        <h2 className="mr-2 text-xl font-bold">イベント管理</h2>
        <AdminEventFlowHintButton />
      </div>
      <button
        className="my-2 block flex w-full items-center rounded border border-gray-300 p-2 text-blue-500 shadow hover:underline"
        onClick={() => {
          setShowCreateEventModal(true);
        }}
        type="button"
      >
        <MdCreate className="text-xl mr-1" />
        <div>新しいイベントを計画</div>
      </button>
      {events &&
        events.length > 0 &&
        events
          .slice(0, 2)
          .map((event) => <AdminEventRow key={event.id} event={event} />)}

      <Link
        className="my-2 block flex items-center rounded border border-gray-300 p-2 text-blue-500 shadow hover:underline"
        to="/manager/event"
      >
        <FaListUl className="mr-2" />
        <div>全てのイベントを見る</div>
      </Link>

      <h2 className="my-4 text-xl font-bold">設定</h2>
      <Link
        className="block flex items-center rounded border border-gray-300 p-2 text-blue-500 shadow hover:underline"
        to="/manager/settings/bank"
      >
        <MdAccountBalance className="mr-2 text-lg" />
        <div>銀行口座設定</div>
      </Link>

      {showCreateEventModal && (
        <AdminEventCreateModal onClose={() => setShowCreateEventModal(false)} />
      )}
    </div>
  );
};
