import React, { useEffect, useRef } from 'react';

import useSWR from 'swr';

import { api } from '../api';
import { Loading } from './Loading';
import { Modal } from './Modal';

const fetcher = (url: string) => api(url).then((res) => res.data);

type props = {
  id: string;
  isUploading: boolean;
  onComplete: () => void;
  onFail: () => void;
  videoType: 'pre' | 'post';
};

type ProcessStatus = 'processing' | 'completed' | 'error';

export const AdminEventSetupVideoWaitModal: React.FC<props> = ({
  id,
  isUploading,
  onComplete,
  onFail,
  videoType,
}) => {
  const { data } = useSWR<{ status: ProcessStatus }>(
    `/event-video/check/${id}/${videoType}/`,
    fetcher,
    { refreshInterval: 2 },
  );

  const completeRef = useRef(false);

  useEffect(() => {
    if (completeRef.current) return;
    if (!data) return;
    if (isUploading) return;
    if (data.status === 'completed') {
      completeRef.current = true;

      onComplete();
    }
    if (data.status === 'error') {
      completeRef.current = true;
      onFail();
    }
  }, [id, data, onComplete, onFail, isUploading]);

  if (isUploading) {
    return (
      <Modal>
        <div className="flex items-center">
          <div>動画が表示できるように処理しています（準備中）...</div>
          <Loading />
        </div>
      </Modal>
    );
  }

  return (
    <Modal>
      <div className="flex items-center">
        <div>動画が表示できるように処理しています...</div>
        <Loading />
      </div>
      <div>この処理は数分かかる可能性があります</div>
    </Modal>
  );
};
