import react from 'react';
import ReactMarkdown from 'react-markdown';

export const MarkdownPage: React.FC<{ md: string }> = ({ md }) => {
  const [markdownString, setMarkdownString] = react.useState('');
  react.useEffect(() => {
    fetch(md)
      .then((res) => res.text())
      .then((text) => {
        setMarkdownString(text);
      });
  }, []);
  if (!markdownString) return <div>Loading...</div>;
  return (
    <div className="markdown container mx-auto p-2">
      <ReactMarkdown>{markdownString}</ReactMarkdown>
    </div>
  );
};
