import React, { useState } from 'react';
import Cropper from 'react-easy-crop';

import { Modal } from '../admin/Modal';

type Props = {
  imageSrc: string | null;
  onCancel?: () => void;
  onSubmit?: (file: Blob) => void;
};
type Area = {
  x: number;
  y: number;
  width: number;
  height: number;
};

function createImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    // CodeSandboxでCORSエラーを回避するために必要
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });
}

async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
): Promise<string> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return '';
  }

  // canvasサイズを設定
  canvas.width = image.width;
  canvas.height = image.height;

  // canvas上に画像を描画
  ctx.drawImage(image, 0, 0);

  // トリミング後の画像を抽出
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
  );

  // canvasのサイズ指定(切り取り後の画像サイズに更新)
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // 抽出した画像データをcanvasの左隅に貼り付け
  ctx.putImageData(data, 0, 0);

  // canvasを画像に変換
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      if (file !== null) resolve(URL.createObjectURL(file));
    }, 'image/jpeg');
  });
}

const Loading: React.FC<{ className?: string }> = ({ className }) => (
  <div role="status" className={className}>
    <svg
      aria-hidden="true"
      className="mr-2 h-4 w-4 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
    <span className="sr-only">Loading...</span>
  </div>
);

export const ImageResizeModal: React.FC<Props> = ({
  imageSrc,
  onCancel,
  onSubmit,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [processing, setProgressing] = useState(false);

  console.log({ videoSrc: imageSrc });
  if (!imageSrc) {
    return null;
  }
  const mimeType = imageSrc?.split(';')[0].split(':')[1];
  if (!mimeType?.startsWith('image/')) {
    return null;
  }
  const onSubmitButton = async () => {
    setProgressing(true);

    if (!croppedAreaPixels) return;

    const cropped = await getCroppedImg(imageSrc, croppedAreaPixels);

    // get blob

    const res = fetch(cropped);
    const blob = await (await res).blob();

    // TODO: crop video
    onSubmit?.(blob);

    setProgressing(false);
  };

  return (
    <Modal>
      <div>
        <div className="relative" style={{ height: '80vh' }}>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={(croppedArea, croppedAreaPixels) => {
              console.log({ croppedArea, croppedAreaPixels });
              setCroppedAreaPixels(croppedAreaPixels);
            }}
          />
        </div>
        <div className="mt-2">
          <button
            className="inline-flex items-center rounded-sm border border-orange-800 bg-orange-800 px-4 py-1 text-white shadow"
            type="button"
            onClick={() => {
              onSubmitButton();
            }}
          >
            {processing && <Loading />}
            保存
          </button>
          <button
            className="ml-2 rounded-sm border border-orange-800 px-4 py-1 text-orange-800 shadow"
            type="button"
            onClick={() => {
              onCancel?.();
            }}
          >
            キャンセル
          </button>
        </div>
      </div>
    </Modal>
  );
};
