type Props = {
  children: React.ReactNode;
  onClose?: () => void;
};

export const ModalBottom: React.FC<Props> = ({ children, onClose }) => {
  return (
    <>
      <div
        className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        onClick={() => {
          onClose?.();
        }}
      />
      <div className="absolute inset-x-0 bottom-0 flex items-end">
        <div className="w-full overflow-auto rounded-t-lg bg-white p-4 shadow shadow-xl">
          {children}
        </div>
      </div>
    </>
  );
};
