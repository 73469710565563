import React from 'react';
import { FaTimes } from 'react-icons/fa';

import { GiftIcon } from '../GiftIcon';
import { UserIcon } from '../UserIcon';
import { Ceremony } from '../types';
import { ModalBottom } from './ModalBottom';

type Props = {
  ceremony: Ceremony;
  participantName: string;
  onClose?: () => void;
};

export const ParticipantPanel: React.FC<Props> = ({ ceremony, onClose }) => {
  const participants = ceremony.participants
    .map((p) => ({
      ...p,
      gifts: ceremony.gifts
        .filter((g) => g.name === p.name)
        .sort((a, b) => a.amount - b.amount),
    }))
    .map((p) => ({
      ...p,
      totalPrice: p.gifts.reduce((a, b) => a + b.amount, 0),
    }))
    .sort((a, b) => b.totalPrice - a.totalPrice);
  return (
    <ModalBottom onClose={onClose}>
      <div>
        <div className="flex items-center justify-between">
          <h3 className="text-xl font-bold">参加者</h3>
          <button
            className="flex items-center gap-1 text-gray-500"
            onClick={onClose}
          >
            <FaTimes className="text-xl" />
            閉じる
          </button>
        </div>
        <ul>
          {participants.map((p) => (
            <li
              className="flex items-center gap-2 border-b border-gray-300 p-2"
              key={p.name}
            >
              <UserIcon
                name={p.name}
                participants={ceremony.participants}
                className="h-10"
              />
              <div>
                {p.name}
                <div className="flex flex-wrap">
                  {p.gifts.map((g) => (
                    <GiftIcon gift={g} />
                  ))}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </ModalBottom>
  );
};
