import { useMemo } from 'react';

import useSWR from 'swr';

import { api } from './api';

type GiftType = {
  type: string;
  amount: number;
  image: string;
};

const image_map: Partial<Record<string, string>> = {
  // piano
  piano1: require('../src/img/gifts/piano_1.png'),
  piano2: require('../src/img/gifts/piano_2.png'),
  piano3: require('../src/img/gifts/piano_3.png'),
  piano4: require('../src/img/gifts/piano_4.png'),
  piano5: require('../src/img/gifts/piano_5.png'),

  // pet
  '1': require('../src/img/gifts/1.png'),
  '2': require('../src/img/gifts/2.png'),
  '3': require('../src/img/gifts/3.png'),
  '4': require('../src/img/gifts/4.png'),
  '5': require('../src/img/gifts/5.png'),
  '6': require('../src/img/gifts/6.png'),
  '7': require('../src/img/gifts/7.png'),
  '8': require('../src/img/gifts/8.png'),

  // show
  flower1: require('../src/img/flower-big2.png'),
  flower2: require('../src/img/flower-big1.png'),
  flower3: require('../src/img/flower-big4.png'),
  flower4: require('../src/img/flower-big3.png'),
  flower5: require('../src/img/flower1.png'),
};

const fetcher = (url: string) => api.get(url).then((res) => res.data);

type APIResponse = {
  gifts: {
    type: string;
    amount: number;
  }[];
};
export const useGifts = (): GiftType[] | null => {
  const { data } = useSWR<APIResponse>(`/gifts/`, fetcher);
  const memo = useMemo(() => {
    if (!data) return null;
    return data.gifts.map((gift) => ({
      ...gift,
      image: image_map[gift.type] ?? '',
    }));
  }, [data]);
  return memo;
};
