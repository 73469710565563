// https://gist.github.com/shimahi/71b95ffb9285fadb4d211ade9e3f591a
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { addDays, isFuture } from 'date-fns';
import ja from 'date-fns/locale/ja';

registerLocale('ja', ja);

const isTodayOrAfter = (date: Date) => {
  return date >= addDays(new Date(), -1);
};

export const DatePickerInput = (props: ReactDatePickerProps) => {
  return (
    <DatePicker
      {...props}
      locale="ja"
      dateFormat="yyyy/MM/dd HH:mm"
      filterDate={isTodayOrAfter}
      filterTime={isFuture}
      showTimeSelect
    />
  );
};
