// implement card component using tailwindcss
import React from 'react';
import { MdCreate } from 'react-icons/md';

export const Card: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div
      className={`border-grey-400 m-4 rounded border p-4 shadow-lg ${className}`}
    >
      {children}
    </div>
  );
};

export const AdminSetupCardClosed: React.FC<{
  children: React.ReactNode;
  title: string;
  className?: string;
  onEditButton?: () => void;
}> = ({ children, className, onEditButton: onClick, title }) => {
  return (
    <Card className={className}>
      <div className="mb-2 flex items-center justify-start">
        <h2 className="text-2xl font-bold">{title}</h2>
        <div
          className="ml-2 flex cursor-pointer items-center text-xs text-blue-500"
          onClick={onClick}
        >
          <MdCreate className="text-sm" />
          編集
        </div>
      </div>
      {children}
    </Card>
  );
};

export const CardTitle: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <h2 className="mb-2 text-2xl font-bold">{children}</h2>;
};
