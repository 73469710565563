import { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';

import { GiftIcon } from '../GiftIcon';
import { useGifts } from '../gift';
import { Ceremony, Gift } from '../types';
import { useFlag } from '../useFlag';
import { ModalBottom } from './ModalBottom';

type Props = {
  ceremony: Ceremony;
  participantName: string;
  onClose?: () => void;
};

export const PaymentPanel: React.FC<Props> = ({
  ceremony,
  participantName,
  onClose,
}) => {
  const GIFT_TYPES = useGifts();
  const isFree = useFlag('free_items');

  const [paymentCounts, setPaymentCounts] = useState<number[]>([]);

  useEffect(() => {
    if (!GIFT_TYPES) return;
    setPaymentCounts(GIFT_TYPES.map(() => 0));
    console.log('reset');
  }, [GIFT_TYPES]);

  if (GIFT_TYPES == null) return null;
  if (isFree == null) return null;

  const giftList: Pick<Gift, 'item' | 'amount'>[] = GIFT_TYPES.flatMap(
    ({ type, amount }, i) =>
      Array.from({ length: paymentCounts[i] }).map((_) => ({
        item: type,
        amount,
      })),
  );

  const incrementItem = (i: number) => {
    const newCounts = [...paymentCounts];
    newCounts[i] += 1;
    setPaymentCounts(newCounts);
  };

  const totalAmount = isFree
    ? 0
    : giftList.reduce(
        (a, b) => a + (GIFT_TYPES.find((g) => g.type === b.item)?.amount ?? 0),
        0,
      );
  const totalCount = giftList.length;

  const payload = {
    ceremony_token: ceremony.token,
    items: giftList,
    name: participantName,
  };

  const resetCounts = () => {
    setPaymentCounts(GIFT_TYPES.map(() => 0));
  };

  return (
    <ModalBottom onClose={onClose}>
      <div className="flex h-full flex-col p-3">
        <div className="flex items-center justify-between">
          <h3 className="text-xl font-bold">プレゼント</h3>
          <button
            className="flex items-center gap-1 text-gray-500"
            onClick={onClose}
          >
            <FaTimes className="text-xl" />
            閉じる
          </button>
        </div>
        <div className="flex-1">
          <div className="flex flex-wrap justify-between">
            {GIFT_TYPES.map((g, i) => {
              return (
                <button
                  type="button"
                  className="relative m-1 flex flex-col items-center rounded border border-gray-300 bg-white p-1"
                  onClick={() => incrementItem(i)}
                >
                  <GiftIcon
                    gift={{ item: g.type, amount: g.amount }}
                    className="h-20 w-20"
                  />
                  {isFree ? (
                    <span>
                      <span className="text-sm line-through">{g.amount}円</span>
                      →<span className="font-bold text-red-500">無料</span>
                    </span>
                  ) : (
                    <span>{g.amount}円</span>
                  )}
                  {paymentCounts[i] > 0 && (
                    <div
                      className="absolute flex items-center justify-center rounded-full bg-red-500 text-center text-sm text-white"
                      style={{
                        top: '-0.5em',
                        right: '-0.5em',
                        height: '2em',
                        width: '2em',
                      }}
                    >
                      <div>{paymentCounts[i]}</div>
                    </div>
                  )}
                </button>
              );
            })}
          </div>
        </div>
        {totalCount > 0 && (
          <form action="/payment/confirm/" method="POST">
            <input type="hidden" name="amount" value={`${totalAmount}`} />
            <input type="hidden" name="kind" value="ceremony-gift" />
            <input type="hidden" name="overview" value={'贈りもの'} />
            <input
              type="hidden"
              name="payload"
              value={JSON.stringify(payload)}
            />
            <input type="hidden" name="callback" value={window.location.href} />
            <button
              className="white mb-2 w-full rounded bg-sub p-1 text-white"
              type={ceremony.is_manager ? 'button' : 'submit'}
            >
              {totalCount}個を購入 (
              {isFree ? (
                <span>
                  <span className="text-sm line-through">{totalAmount}円</span>→
                  <span className="font-bold">無料</span>
                </span>
              ) : (
                <span>{totalAmount}円</span>
              )}
              )
            </button>
            <button
              className="white mb-2 w-full rounded bg-gray-600 p-1 text-white"
              onClick={resetCounts}
              type="button"
            >
              リセット
            </button>
          </form>
        )}
      </div>
    </ModalBottom>
  );
};
