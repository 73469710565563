import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from 'react-router-dom';

import { Login } from './Login';
import { SERVICE_TYPE } from './SERVICE_TYPE';
import { ServiceDescription } from './ServiceDescription';
import { TopPage } from './TopPage';
import { AdminEventAfter } from './admin/AdminEventAfter';
import { AdminEventInvite } from './admin/AdminEventInvite';
import { AdminEventList } from './admin/AdminEventList';
import { AdminEventPayment } from './admin/AdminEventPayment';
import { AdminEventRealtime } from './admin/AdminEventRealtime';
import { AdminEventSetup } from './admin/AdminEventSetup';
import { AdminIndex } from './admin/AdminIndex';
import { AdminRoot } from './admin/AdminRoot';
import { BankInfoPage } from './admin/settings/BankInfoPage';
import { api } from './api';
import { MarkdownPage } from './content_page/MarkdownPage';
import './index.css';
import { CeremonyParticipantPage } from './participant/CeremonyParticipantPage';

export const NotFoundPage: React.FC = () => {
  const error = useRouteError();
  console.log(error);

  return (
    <div className="p-2">
      <h1 className="text-2xl">ページが見つかりません</h1>
      <p>
        <a
          className="my-2 block w-full rounded border border-blue-500 px-4 py-1 text-center text-blue-500 shadow"
          href="/accounts/login"
        >
          ログインページへ
        </a>
      </p>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <TopPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/privacy',
    element: {
      pet: <MarkdownPage md={require('./markdown/pet/privacy.md')} />,
      piano: <MarkdownPage md={require('./markdown/piano/privacy.md')} />,
      show: <MarkdownPage md={require('./markdown/show/privacy.md')} />,
    }[SERVICE_TYPE],
    errorElement: <NotFoundPage />,
  },
  {
    path: '/tos',
    element: {
      pet: <MarkdownPage md={require('./markdown/pet/tos.md')} />,
      piano: <MarkdownPage md={require('./markdown/piano/tos.md')} />,
      show: <MarkdownPage md={require('./markdown/show/tos.md')} />,
    }[SERVICE_TYPE],
    errorElement: <NotFoundPage />,
  },
  {
    path: '/tokusho',
    element: {
      pet: <MarkdownPage md={require('./markdown/pet/tokusho.md')} />,
      piano: <MarkdownPage md={require('./markdown/nem/tokusho.md')} />,
      show: <MarkdownPage md={require('./markdown/nem/tokusho.md')} />,
    }[SERVICE_TYPE],
    errorElement: <NotFoundPage />,
  },
  {
    path: '/about_us',
    element: {
      pet: <MarkdownPage md={require('./markdown/pet/about_us.md')} />,
      piano: <MarkdownPage md={require('./markdown/nem/about_us.md')} />,
      show: <MarkdownPage md={require('./markdown/nem/about_us.md')} />,
    }[SERVICE_TYPE],
    errorElement: <NotFoundPage />,
  },
  {
    path: 'ceremony/:token',
    element: <CeremonyParticipantPage />,
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'manager',
    element: <AdminRoot />,
    children: [
      { path: '', element: <AdminIndex /> },
      {
        path: 'event',
        element: <AdminEventList />,
      },
      {
        path: 'event/:id/setup/',
        element: <AdminEventSetup />,
      },
      {
        path: 'event/:id/payment/',
        element: <AdminEventPayment />,
      },
      {
        path: 'event/:id/invite/',
        element: <AdminEventInvite />,
      },
      {
        path: 'event/:id/realtime/',
        element: <AdminEventRealtime />,
      },
      {
        path: 'event/:id/after/',
        element: <AdminEventAfter />,
      },
      {
        path: 'settings/bank',
        element: <BankInfoPage />,
      },
    ],
  },
  {
    path: 'service-description',
    element: <ServiceDescription />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>,
);

window.onerror = (message, file, lineNo, colNo, error) => {
  api
    .post('/collect_error/', {
      error: message,
    })
    .catch((e) => {
      console.error(e);
    });
};
window.addEventListener('unhandledrejection', function (event) {
  api
    .post('/collect_error/', {
      error: 'Unhandled promise rejection: ' + event.reason,
    })
    .catch((e) => {
      console.error(e);
    });
});
