import axios from 'axios';

export const api = axios.create({
  baseURL: '/api/',
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'axios',
  },
});
