import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from 'react-icons/md';

export const CopyableLink = ({ link }: { link: string }) => {
  const [copied, setCopied] = useState(false);
  return (
    <>
      <div>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="break-all text-blue-500 underline"
        >
          {link}
        </a>
      </div>
      <div>
        <CopyToClipboard
          text={link}
          onCopy={() => {
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 3000);
          }}
        >
          <button className="mt-2 rounded bg-gray-300 px-2 py-2 text-sm text-black shadow inline-flex items-center">
            <MdContentCopy className="mr-2 text-xl" />
            リンクをコピーする
            {copied && (
              <div className="absolute">
                <div className="rounded border border-gray-300 bg-gray-100 p-2">
                  コピーしました
                </div>
              </div>
            )}
          </button>
        </CopyToClipboard>
      </div>
    </>
  );
};
