import React, { useState } from 'react';

import useSWR, { useSWRConfig } from 'swr';

import { THUMBNAIL_ALWAYS_ENABLED } from '../SERVICE_TYPE';
import { api } from '../api';
import { AdminEvent } from '../types';
import { AdminEventSetupVideoWaitModal } from './AdminEventSetupVideoWaitModal';
import { AdminSetupCardClosed, Card, CardTitle } from './Card';
import { ImageResizeModal } from './ImageResizeModal';

type MediaType = 'video' | 'image' | 'none';

const fetcher = (url: string) => api(url).then((res) => res.data);

export const AdminEventSetupVideo: React.FC<{
  event: AdminEvent;
  videoType: 'pre' | 'post';
}> = ({ event, videoType }) => {
  const currentMediaType = {
    pre: event.ceremony_image_type,
    post: event.post_media_type,
  }[videoType];
  const currentImage = {
    pre: event.ceremony_image,
    post: event.post_image,
  }[videoType];

  const allFilled = !!currentMediaType;
  const [isOpened, setIsOpened] = useState(!allFilled);
  const [mediaType, setMediaType] = useState<MediaType>(
    currentMediaType || 'video',
  );

  const { mutate } = useSWRConfig();
  const { data: video } = useSWR<{ url: string }>(
    `/event-video/get-link/admin/${event.id}/${videoType}/`,
    fetcher,
  );

  const [saving, setSaving] = useState(false);

  const submit = async (mediaType: MediaType, file: File | Blob | null) => {
    if (saving) return;
    setSaving(true);

    if (mediaType === 'image' && file === null) {
      throw new Error('file is null');
    }

    const formData = new FormData();
    switch (videoType) {
      case 'pre':
        formData.append('ceremony_image_type', mediaType);
        break;
      case 'post':
        formData.append('post_media_type', mediaType);
        break;
    }

    if (mediaType === 'image' && file !== null) {
      switch (videoType) {
        case 'pre':
          formData.append('ceremony_image', file);
          break;
        case 'post':
          formData.append('post_image', file);
          break;
      }
    }

    await api.patch(`/manager/ceremony/${event.id}/`, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });

    mutate(`/manager/ceremony/${event.id}/`);
    mutate(`/api/event-video/get-link/${event.token}/${videoType}/`);

    setIsOpened(false);
    setSaving(false);
  };

  const onCancel = () => {
    setMediaType(currentMediaType || 'video');
    setIsOpened(false);
  };

  const uploadVideo = async (file: File, id: string) => {
    const formData = new FormData();
    formData.append('file', file);
    await api.post(`/event-video/upload/${id}/${videoType}/`, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });
    mutate(`/event-video/check/${id}/${videoType}/`);
  };

  const [showVideoWaitModal, setShowVideoWaitModal] = useState(false);
  const [isVideoUploadingForConvert, setIsVideoUploadingForConvert] =
    useState(false);
  const [uploadId, setUploadId] = useState<string | null>(null);

  const onVideoFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const id = event.id;
    setUploadId(id);

    setShowVideoWaitModal(true);
    setIsVideoUploadingForConvert(true);
    await uploadVideo(e.target.files[0], id);
    setIsVideoUploadingForConvert(false);
  };

  const [showImageModal, setShowImageModal] = React.useState(false);
  const [imageSrc, setImageSrc] = React.useState<string | null>(null);

  const onImageFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (reader.result) {
          setImageSrc(reader.result.toString() || '');
          setShowImageModal(true);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  if (!isOpened) {
    const ceremony_image_type_ja = {
      video: '動画',
      image: '画像',
      none: 'なし',
    }[currentMediaType || 'none'];
    return (
      <AdminSetupCardClosed
        title={
          '紹介動画・紹介写真設定' +
          (videoType === 'pre' ? '(イベント前)' : '(イベント後)')
        }
        onEditButton={() => {
          setIsOpened(true);
        }}
      >
        <div>
          <div>
            種類：
            <span className="font-bold">{ceremony_image_type_ja}</span>
          </div>
        </div>
        {currentMediaType === 'video' && (
          <div>
            {video?.url ? (
              <div>
                <video controls className="h-64" crossOrigin="anonymous">
                  <source src={video.url} />
                </video>
              </div>
            ) : (
              <div>動画：未設定</div>
            )}
          </div>
        )}

        {currentMediaType === 'image' && (
          <div>
            {currentImage ? (
              <div>
                <img
                  src={currentImage}
                  alt=""
                  className="h-64"
                  crossOrigin="anonymous"
                />
              </div>
            ) : (
              <div>画像：未設定</div>
            )}
          </div>
        )}
      </AdminSetupCardClosed>
    );
  }

  return (
    <Card>
      <CardTitle>
        紹介動画・紹介写真設定
        {videoType === 'pre' ? '(イベント前)' : '(イベント後)'}
      </CardTitle>
      {videoType === 'pre' && (
        <p>紹介動画・写真はライブ配信開始30分前からお客様に表示されます。</p>
      )}
      {videoType === 'post' && (
        <p>紹介動画・写真はライブ配信終了後にお客様に表示されます。</p>
      )}
      <p>
        30秒以内の紹介動画または紹介写真を作成して事前にアップロードしてください。
      </p>
      <form>
        <div className="my-2">
          種類：
          <select
            onChange={(e) => {
              setMediaType(e.target.value as MediaType);
            }}
            value={mediaType}
          >
            <option value="video">動画 (1,000円)</option>
            <option value="image">画像 (500円)</option>
            {!THUMBNAIL_ALWAYS_ENABLED && <option value="none">なし</option>}
          </select>
        </div>

        {mediaType === 'video' && (
          <>
            {video?.url && (
              <div>
                現在の動画
                <video controls className="h-64">
                  <source src={video.url} />
                </video>
              </div>
            )}

            {showVideoWaitModal && uploadId && (
              <AdminEventSetupVideoWaitModal
                id={uploadId}
                isUploading={isVideoUploadingForConvert}
                videoType={videoType}
                onFail={() => {
                  window.alert('動画のアップロードに失敗しました');
                  setShowVideoWaitModal(false);
                }}
                onComplete={() => {
                  (async () => {
                    await submit('video', null);
                    setShowVideoWaitModal(false);
                  })();
                }}
              />
            )}

            <div className="my-2">
              <label
                className="rounded border border-blue-500 bg-blue-500 px-4 py-1 text-white shadow"
                htmlFor="videoUploadInput"
              >
                動画ファイルを選択
              </label>
              <input
                className="hidden"
                type="file"
                id="videoUploadInput"
                accept="video/*"
                onChange={onVideoFileChange}
              />
            </div>
          </>
        )}
        {mediaType === 'image' && (
          <>
            {currentImage && (
              <div>
                現在の画像
                <img
                  src={currentImage}
                  alt=""
                  className="h-64"
                  crossOrigin="anonymous"
                />
              </div>
            )}

            {showImageModal && (
              <ImageResizeModal
                imageSrc={imageSrc}
                onCancel={() => {
                  setShowImageModal(false);
                }}
                onSubmit={async (image) => {
                  setShowImageModal(false);

                  // submit
                  submit('image', image);
                }}
              />
            )}

            <div className="my-2">
              <label
                className="rounded border border-blue-500 bg-blue-500 px-4 py-1 text-white shadow"
                htmlFor="imageUploadInput"
              >
                画像ファイルを選択
              </label>
              <input
                className="hidden"
                id="imageUploadInput"
                type="file"
                accept="image/*"
                onChange={onImageFileChange}
              />
            </div>
          </>
        )}

        {mediaType === 'none' && (
          <button
            className="mr-2 rounded border border-orange-800 bg-orange-800 px-4 py-1 text-white shadow"
            type="button"
            onClick={() => {
              submit('none', null);
            }}
          >
            {allFilled ? '保存' : '保存して次へ'}
          </button>
        )}

        {allFilled && (
          <button
            className="rounded border border-orange-800 px-4 py-1 text-orange-800 shadow"
            type="button"
            onClick={onCancel}
          >
            キャンセル
          </button>
        )}
      </form>
    </Card>
  );
};
