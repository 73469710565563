type Props = {
  variant?: 'error' | 'info' | 'success' | 'warning';
  children: React.ReactNode;
};
export const Alert: React.FC<Props> = ({ variant = 'info', children }) => {
  switch (variant) {
    case 'error':
      return (
        <div className="my-2 rounded-lg border-2 border-red-500 bg-red-100 p-2 text-center text-red-900">
          {children}
        </div>
      );
    case 'info':
      return (
        <div className="my-2 rounded-lg border-2 border-blue-500 bg-blue-100 p-2 text-center text-blue-900">
          {children}
        </div>
      );
    case 'success':
      return (
        <div className="my-2 rounded-lg border-2 border-green-500 bg-green-100 p-2 text-center text-green-900">
          {children}
        </div>
      );
    case 'warning':
      return (
        <div className="my-2 rounded-lg border-2 border-yellow-500 bg-yellow-100 p-2 text-center text-yellow-900">
          {children}
        </div>
      );
  }
};
