const hsl2rgb1 = (hsl: [number, number, number]) => {
  const [h, s, l] = hsl;

  const max = l + (s * (1 - Math.abs(2 * l - 1))) / 2;
  const min = l - (s * (1 - Math.abs(2 * l - 1))) / 2;

  const i = Math.floor(h / 60);

  switch (i) {
    case 0:
    case 6:
      return [max, min + (max - min) * (h / 60), min];

    case 1:
      return [min + (max - min) * ((120 - h) / 60), max, min];

    case 2:
      return [min, max, min + (max - min) * ((h - 120) / 60)];

    case 3:
      return [min, min + (max - min) * ((240 - h) / 60), max];

    case 4:
      return [min + (max - min) * ((h - 240) / 60), min, max];

    case 5:
      return [max, min, min + (max - min) * ((360 - h) / 60)];
    default:
      throw new Error('hsl2rgb1: i is out of range');
  }
};

const hsl2rgb = (hsl: [number, number, number]) => {
  const rgb = hsl2rgb1(hsl);
  return rgb.map(function (value) {
    return Math.floor(value * 255);
  });
};

const getRandomRgb = () => {
  const h = Math.random() * 360;
  const s = 1;
  const l = 0.5;
  return hsl2rgb([h, s, l]);
};

export const getRandomRgbString = () => {
  const rgb = getRandomRgb();
  return `${rgb.map((v) => v.toString(16).padStart(2, '0')).join('')}`;
};
