import { useState } from 'react';
import { MdSend } from 'react-icons/md';

import clsx from 'clsx';
import { useSWRConfig } from 'swr';

import { api } from '../api';
import { AdminEvent } from '../types';

export const AdminChatSend: React.FC<{
  event: AdminEvent;
  className?: string;
}> = ({ event, className }) => {
  const [message, setMessage] = useState('');
  const { mutate } = useSWRConfig();

  const sendMessage = async (message: string) => {
    try {
      await api.post(`/manager/ceremony/${event.id}/message/`, {
        message: message,
      });
      mutate(`/manager/ceremony/${event.id}/`);
    } catch (e) {
      window.alert('エラーが発生しました。');
      console.error(e);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (message.length > 0) {
      sendMessage(message);
      setMessage('');
    }
  };

  return (
    <form className={clsx('flex text-live-ui', className)} onSubmit={onSubmit}>
      <input
        className="flex-1 rounded-xl border border-live-ui bg-white px-2 py-1 outline-black"
        placeholder="主催者としてコメント"
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      />
      <button type="submit" className="mx-2 rounded-full p-1">
        <MdSend className="text-2xl" />
      </button>
    </form>
  );
};
