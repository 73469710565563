import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { format, formatISO, parseISO } from 'date-fns';
import useSWR, { useSWRConfig } from 'swr';

import { LIVE_ALWAYS_ENABLED, SERVICE_TYPE } from '../SERVICE_TYPE';
import { api } from '../api';
import { useFrames } from '../frames';
import { CeremonyParticipantContent } from '../participant/CeremonyParticipantPage';
import { AdminEvent } from '../types';
import { LOGIN_URL } from '../url';
import { useFlag } from '../useFlag';
import { AdminEventHeader } from './AdminEventHeader';
import { AdminEventSetupEntranceFee } from './AdminEventSetupEntranceFee';
import { AdminEventSetupPrePost } from './AdminEventSetupPrePost';
import { AdminEventSetupVideo } from './AdminEventSetupVideo';
import { Alert } from './Alert';
import { AdminSetupCardClosed, Card, CardTitle } from './Card';
import { DatePickerInput } from './DateInput';
import { Loading } from './Loading';
import { ModalFullScreen } from './Modal';

const fetcher = (url: string) => api(url).then((res) => res.data);

export const AdminEventSetup: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const isFree = useFlag('free_items');
  const entranceFeeEnabled = useFlag('ENTRANCE_FEE');
  const { data: event, error } = useSWR<AdminEvent>(
    `/manager/ceremony/${id}/`,
    fetcher,
  );
  const navigate = useNavigate();
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (event && event.event_status === 'finished') {
      navigate(`/manager/event/${event.id}/after/`);
    }
  }, [event, navigate]);

  if (error) {
    if (error.response?.status === 401) {
      window.location.href = LOGIN_URL;
      return null;
    }
    if (error.response?.status === 403) {
      return <div>権限がありません</div>;
    }
    return <div>エラーが発生しました</div>;
  }
  if (!event) {
    return <div>Loading...</div>;
  }
  if (isFree == null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4">
      <AdminEventHeader active="setup" event={event} />

      {isFree && (
        <Alert variant="info">現在テスト期間中、全ての利用料は無料です。</Alert>
      )}
      {event.offered && (
        <Alert variant="info">
          特別オファーのため、全ての利用料は無料です。
        </Alert>
      )}

      {event.is_payment_completed && event.event_status === 'running' && (
        <Alert variant="error">
          <p className="text-lg font-bold">
            このイベントはすでに開始しています。
          </p>
          <p>
            設定を変更すると、追加の支払いが必要となる場合があります。
            <br />
            その場合、追加の支払いが完了するまでイベントは一時的に非公開となります。
          </p>
          <p>
            設定変更が不要であれば、
            <Link
              to={`/manager/event/${event.id}/realtime/`}
              className="text-blue-500 hover:underline"
              rel="noreferrer"
            >
              当日画面
            </Link>
            へ進んでください。
          </p>
        </Alert>
      )}

      {event.is_payment_completed &&
        event.event_status === 'not_started' &&
        event.completed_payments.length > 0 && (
          <Alert variant="warning">
            <p className="text-lg font-bold">
              このイベントは支払いが完了しています。
            </p>
            <p>設定を変更すると、追加の支払いが必要となる場合があります。</p>
            <p>
              設定変更が不要であれば、
              <Link
                to={`/manager/event/${event.id}/invite/`}
                className="text-blue-500 hover:underline"
                rel="noreferrer"
              >
                参加者招待
              </Link>
              へ進んでください。
            </p>
          </Alert>
        )}

      <div className="text-center">
        下記全ての項目を設定し、イベント開催の準備をしましょう。
      </div>

      <AdminEventSetupSummary event={event} />
      {entranceFeeEnabled === '1' && (
        <AdminEventSetupEntranceFee event={event} />
      )}
      <AdminEventSetupVideo event={event} videoType="pre" />
      <AdminEventSetupPrePost event={event} variant="pre" />
      <AdminEventSetupVideo event={event} videoType="post" />
      <AdminEventSetupPrePost event={event} variant="post" />
      {!LIVE_ALWAYS_ENABLED && <AdminEventSetupLive event={event} />}
      {(['image', 'video'].includes(event.ceremony_image_type) ||
        event.live_enabled === 'yes') && <AdminEventSetupFrame event={event} />}
      <AdminEventSetupChatEnable event={event} />
      <AdminEventSetupPreview
        event={event}
        onClick={() => setShowPreview(true)}
      />
      <div className="my-2 text-center">
        <div className="my-2 text-center">
          参加者様からのプレゼント売り上げのうち、70%が主催者様に支払われます。
        </div>
        {!event.is_setup_completed && (
          <div className="my-2 font-bold text-red-500">
            設定が完了していない項目があります。全ての項目で「保存」ボタンを押してください。
          </div>
        )}
        <Link
          to={
            event.is_payment_completed
              ? `/manager/event/${event.id}/invite/`
              : `/manager/event/${event.id}/payment/`
          }
          className={
            'rounded px-4 py-2 text-lg shadow ' +
            (event.is_setup_completed
              ? 'bg-orange-300'
              : 'bg-gray-300 text-white')
          }
          rel="noreferrer"
          onClick={(e) => {
            return event.is_setup_completed ? null : e.preventDefault();
          }}
        >
          次へ &gt;
        </Link>
      </div>
      {showPreview && (
        <PreviewPopup
          token={event.token}
          setupCompleted={event.is_setup_completed}
          onClose={() => setShowPreview(false)}
          onConfirm={() => {
            setShowPreview(false);
            navigate(
              event.is_payment_completed
                ? `/manager/event/${event.id}/invite/`
                : `/manager/event/${event.id}/payment/`,
            );
          }}
        />
      )}
    </div>
  );
};

const AdminEventSetupSummary: React.FC<{ event: AdminEvent }> = ({ event }) => {
  type Inputs = {
    pet_name: string;
    event_name: string;
    event_subtitle: string;
    starts_at: string | null;
    event_length: number;
    participants_limit: number;
  };

  const defaultValues: Inputs = {
    pet_name: event.pet_name,
    event_name: event.event_name,
    event_subtitle: event.event_subtitle || '',
    starts_at: event.starts_at,
    event_length: event.event_length || 30,
    participants_limit: event.participants_limit || 30,
  };

  const allFilled =
    event.pet_name &&
    event.event_name &&
    event.starts_at &&
    event.event_length &&
    event.participants_limit;
  const [isOpened, setIsOpened] = React.useState(!allFilled);

  const [validationFailed, setValidationFailed] = React.useState(false);

  const { mutate } = useSWRConfig();

  const { register, handleSubmit, control, reset } = useForm<Inputs>({
    defaultValues,
  });

  const isValid = (data: Inputs) => {
    return (
      data.pet_name &&
      data.event_name &&
      data.starts_at &&
      data.event_length &&
      data.participants_limit
    );
  };

  const onSubmit = async (data: Inputs) => {
    // TODO: update
    if (!isValid(data)) {
      setValidationFailed(true);
      return;
    }
    await api.patch(`/manager/ceremony/${event.id}/`, data);
    mutate(`/manager/ceremony/${event.id}/`);
    setIsOpened(false);
    setValidationFailed(false);
  };

  const onCancel = () => {
    reset(defaultValues);
    setIsOpened(false);
  };

  if (!isOpened) {
    return (
      <AdminSetupCardClosed
        title="基本設定"
        onEditButton={() => {
          setIsOpened(true);
        }}
      >
        <div>
          <div>
            {SERVICE_TYPE === 'pet' ? 'ペットの名前：' : '演者の名前：'}
            <span className="font-bold">{event.pet_name}</span>
          </div>
          <div>
            イベントタイトル：
            <span className="font-bold">{event.event_name}</span>
          </div>
          <div>
            サブタイトル：
            <span className="font-bold">{event.event_subtitle}</span>
          </div>
          <div>
            開始日時：
            <span className="font-bold">
              {event.starts_at
                ? format(parseISO(event.starts_at), 'yyyy/MM/dd HH:mm')
                : '未設定'}
            </span>
          </div>
          <div>
            イベント時間：
            <span className="font-bold">{event.event_length}分</span>
          </div>
          <div>
            参加者上限：
            <span className="font-bold">{event.participants_limit}人</span>
          </div>
        </div>
      </AdminSetupCardClosed>
    );
  }

  return (
    <Card>
      <CardTitle>基本設定</CardTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2">
          {SERVICE_TYPE === 'pet' ? 'ペットの名前：' : '演者の名前：'}
          <input
            className="rounded border border-orange-400 p-1 shadow"
            {...register('pet_name')}
          />
        </div>
        {SERVICE_TYPE === 'pet' && (
          <div className="my-1 text-xs text-gray-500">
            ペットの名前を入力してください。
          </div>
        )}
        {SERVICE_TYPE !== 'pet' && (
          <div className="my-1 text-xs text-gray-500">
            あなたの名前を入力してください。芸名・ニックネームでも問題ありません。
          </div>
        )}
        <div className="mt-2">
          イベントタイトル：
          <input
            className="rounded border border-orange-400 p-1 shadow"
            {...register('event_name')}
          />
        </div>
        <div className="my-1 text-xs text-gray-500">
          イベントのタイトルを変更したい場合は、こちらに入力してください。
        </div>
        <div className="mt-2">
          サブタイトル：
          <input
            className="rounded border border-orange-400 p-1 shadow"
            {...register('event_subtitle')}
          />
        </div>
        <div className="my-1 text-xs text-gray-500">
          イベントタイトルの下に表示される、イベントのサブタイトルを入力してください。サブタイトルが不要な場合は、空欄のままにしてください。
        </div>
        <div className="mt-2">
          開始日時：
          <Controller
            control={control}
            name="starts_at"
            render={({ field: { onChange, onBlur, value } }) => (
              <DatePickerInput
                className="rounded border border-orange-400 p-1 shadow"
                onChange={(e) => {
                  e && onChange(formatISO(e));
                }}
                onBlur={onBlur}
                selected={value ? parseISO(value) : null}
              />
            )}
          />
        </div>
        <div className="my-1 text-xs text-gray-500">
          イベントの開始時刻を設定してください。イベントの開始時刻は、イベント開始前に参加者に表示されます。
          設定した時刻の30分前になると、参加者はイベントに参加できるようになります。
          <br />
          開始時刻になったら、ライブ配信を開始できるようになります。
        </div>
        <div className="mt-2">
          イベント時間：
          <select {...register('event_length')}>
            <option value="30">30分</option>
            <option value="60">60分</option>
            <option value="90">90分</option>
          </select>
        </div>
        <div className="my-1 text-xs text-gray-500">
          イベントの長さを設定してください。
          設定した時間が経過するか、イベント主催者がイベントを終了すると、イベントは終了します。
        </div>
        <div className="mt-2">
          参加者の人数制限：
          <select {...register('participants_limit')}>
            <option value="30">30人</option>
            <option value="60">60人</option>
            <option value="90">90人</option>
          </select>
        </div>
        <div className="my-1 text-xs text-gray-500">
          イベントの制限人数を設定してください。
        </div>

        {validationFailed && (
          <Alert variant="warning">入力内容に不備があります。</Alert>
        )}

        <button
          className="mt-3 rounded border border-orange-800 bg-orange-800 px-4 py-1 text-white shadow"
          type="submit"
        >
          保存して次へ
        </button>
        {allFilled && (
          <button
            className="ml-2 rounded border border-orange-800 px-4 py-1 text-orange-800 shadow"
            type="button"
            onClick={onCancel}
          >
            キャンセル
          </button>
        )}
      </form>
    </Card>
  );
};

const AdminEventSetupPreview: React.FC<{
  event: AdminEvent;
  onClick?: () => void;
}> = ({ onClick }) => {
  return (
    <Card>
      <CardTitle>プレビュー</CardTitle>
      <div className="my-2">設定したイベントの見た目を確認しましょう。</div>
      <div className="my-2">
        <button
          onClick={() => {
            onClick?.();
          }}
          className="rounded border border-orange-800 bg-orange-800 px-4 py-1 text-white shadow"
          rel="noreferrer"
        >
          プレビューを開く
        </button>
      </div>
    </Card>
  );
};

const AdminEventSetupFrame: React.FC<{ event: AdminEvent }> = ({ event }) => {
  const frames = useFrames();
  type Inputs = {
    ceremony_frame: string;
  };

  const defaultValues: Inputs = {
    ceremony_frame: event.ceremony_frame ?? '',
  };

  const allFilled = event.ceremony_frame != null;

  const [isOpened, setIsOpened] = React.useState(!allFilled);

  const { mutate } = useSWRConfig();

  const { register, handleSubmit, reset } = useForm<Inputs>({
    defaultValues,
  });

  const [saving, setSaving] = React.useState(false);

  const allowNoFrame = useFlag('allow_no_frame');

  const [frameRequired, setFrameRequired] = React.useState(false);

  const isValid = (data: Inputs) => {
    if (!allowNoFrame && !data.ceremony_frame) {
      return false;
    }
    return true;
  };

  const onSubmit = async (data: Inputs) => {
    if (saving) return;
    setSaving(true);

    // validate
    if (!isValid(data)) {
      setFrameRequired(true);
      setSaving(false);
      return;
    }

    await api.patch(`/manager/ceremony/${event.id}/`, data);

    mutate(`/manager/ceremony/${event.id}/`);

    setIsOpened(false);
    setSaving(false);
  };

  const onCancel = () => {
    reset(defaultValues);
    setIsOpened(false);
  };

  if (!frames) {
    return null;
  }

  if (!isOpened) {
    return (
      <AdminSetupCardClosed
        title="フレーム設定"
        onEditButton={() => {
          setIsOpened(true);
        }}
      >
        <div>
          {event.ceremony_frame && (
            <div>
              <img
                src={
                  frames.filter((f) => f.id === event.ceremony_frame)[0].url
                    .full
                }
                alt=""
                className="h-16"
              />
            </div>
          )}
          {!event.ceremony_frame && <div>フレーム：未設定</div>}
        </div>
      </AdminSetupCardClosed>
    );
  }

  return (
    <Card>
      <CardTitle>フレーム設定</CardTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>フレームは紹介動画・画像と、ライブ配信に表示されます。</p>
        <div className="flex flex-wrap">
          {frames.map((frame) => {
            return (
              <div key={frame.id} className="m-2">
                <input
                  type="radio"
                  value={frame.id}
                  id={`frame-${frame.id}`}
                  className="peer hidden"
                  {...register('ceremony_frame')}
                />
                <label
                  htmlFor={`frame-${frame.id}`}
                  className="relative block border-2 border-gray-300 peer-checked:border-red-500"
                >
                  <img src={frame.url.full} alt="" className="h-28" />
                  <span className="absolute bottom-0 right-0 h-8 border border-black bg-white p-1">
                    {frame.price}円
                  </span>
                </label>
              </div>
            );
          })}
        </div>
        {frameRequired && (
          <Alert variant="warning">フレームを選択してください。</Alert>
        )}

        <button
          className="inline-flex items-center rounded border border-orange-800 bg-orange-800 px-4 py-1 text-white shadow"
          type="submit"
        >
          <div>保存して次へ</div>
          {saving && <Loading className="ml-2" />}
        </button>
        {allFilled && (
          <button
            className="ml-2 rounded border border-orange-800 px-4 py-1 text-orange-800 shadow"
            type="button"
            onClick={onCancel}
          >
            キャンセル
          </button>
        )}
      </form>
    </Card>
  );
};

const AdminEventSetupLive: React.FC<{ event: AdminEvent }> = ({ event }) => {
  type Inputs = {
    live_enabled: string;
  };

  const defaultValues: Inputs = {
    live_enabled: event.live_enabled || 'no',
  };

  const allFilled = event.live_enabled !== '';
  const [isOpened, setIsOpened] = React.useState(!allFilled);

  const { mutate } = useSWRConfig();

  const { register, handleSubmit, reset } = useForm<Inputs>({
    defaultValues,
  });

  const onSubmit = async (data: Inputs) => {
    // TODO: update
    await api.patch(`/manager/ceremony/${event.id}/`, data);
    mutate(`/manager/ceremony/${event.id}/`);
    setIsOpened(false);
  };

  const onCancel = () => {
    reset(defaultValues);
    setIsOpened(false);
  };

  if (!isOpened) {
    return (
      <AdminSetupCardClosed
        title="ライブ配信設定"
        onEditButton={() => {
          setIsOpened(true);
        }}
      >
        <div>
          <div>
            ライブ配信：
            <span className="font-bold">
              {event.live_enabled === 'yes' ? '有効' : '無効'}
            </span>
          </div>
        </div>
      </AdminSetupCardClosed>
    );
  }

  return (
    <Card>
      <CardTitle>ライブ配信設定</CardTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-2">
          ライブ配信：
          <select {...register('live_enabled')}>
            <option value="no">無効</option>
            <option value="yes">有効 (1,000円)</option>
          </select>
        </div>

        <button
          className="rounded border border-orange-800 bg-orange-800 px-4 py-1 text-white shadow"
          type="submit"
        >
          保存して次へ
        </button>
        {allFilled && (
          <button
            className="ml-2 rounded border border-orange-800 px-4 py-1 text-orange-800 shadow"
            type="button"
            onClick={onCancel}
          >
            キャンセル
          </button>
        )}
      </form>
    </Card>
  );
};

const AdminEventSetupChatEnable: React.FC<{ event: AdminEvent }> = ({
  event,
}) => {
  type Inputs = {
    message_enabled_running: boolean;
  };

  const defaultValues: Inputs = {
    message_enabled_running: event.message_enabled_running,
  };

  const [isOpened, setIsOpened] = React.useState(false);

  const [validationFailed, setValidationFailed] = React.useState(false);

  const { mutate } = useSWRConfig();

  const { register, handleSubmit, reset } = useForm<Inputs>({
    defaultValues,
  });

  const onSubmit = async (data: Inputs) => {
    await api.patch(`/manager/ceremony/${event.id}/`, data);
    mutate(`/manager/ceremony/${event.id}/`);
    setIsOpened(false);
    setValidationFailed(false);
  };

  const onCancel = () => {
    reset(defaultValues);
    setIsOpened(false);
  };

  if (!isOpened) {
    return (
      <AdminSetupCardClosed
        title="チャット設定"
        onEditButton={() => {
          setIsOpened(true);
        }}
      >
        <div>
          <div>
            ライブ配信時にチャットを有効にする：
            <span className="font-bold">
              {event.message_enabled_running ? '有効' : '無効'}
            </span>
          </div>
        </div>
      </AdminSetupCardClosed>
    );
  }

  return (
    <Card>
      <CardTitle>チャット設定</CardTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          ライブ配信時にチャットを有効にする：
          <input type="checkbox" {...register('message_enabled_running')} />
        </div>

        {validationFailed && (
          <Alert variant="warning">入力内容に不備があります。</Alert>
        )}

        <button
          className="mt-3 rounded border border-orange-800 bg-orange-800 px-4 py-1 text-white shadow"
          type="submit"
        >
          保存して次へ
        </button>
        <button
          className="ml-2 rounded border border-orange-800 px-4 py-1 text-orange-800 shadow"
          type="button"
          onClick={onCancel}
        >
          キャンセル
        </button>
      </form>
    </Card>
  );
};

const PreviewPopup: React.FC<{
  token: string;
  setupCompleted: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
}> = ({ token, setupCompleted, onConfirm, onClose }) => {
  return (
    <ModalFullScreen>
      <div className="flex h-full flex-col">
        <div className="flex-1 border-2 border-gray-500">
          <CeremonyParticipantContent token={token} />
        </div>
        <div className="flex">
          <button
            className="m-2 flex-1 rounded border border-orange-800 px-4 py-1 text-orange-800 shadow"
            type="button"
            onClick={() => onClose?.()}
          >
            戻る
          </button>
          {setupCompleted && (
            <button
              className="m-2 flex-1 rounded border border-orange-800 bg-orange-800 px-4 py-1 text-white shadow"
              type="button"
              onClick={() => onConfirm?.()}
            >
              次へ
            </button>
          )}
        </div>
      </div>
    </ModalFullScreen>
  );
};
