import { Link } from 'react-router-dom';

import useSWR from 'swr';

import { api } from '../api';

const fetcher = (url: string) => api(url).then((res) => res.data);

export const BankInfoAlert: React.FC = () => {
  const { data: status } = useSWR<{ bank_infomation_filled: boolean }>(
    '/me/status/',
    fetcher,
  );
  if (!status) {
    return null;
  }

  if (status.bank_infomation_filled) {
    return null;
  }

  return (
    <div className="bg-yellow-100 p-2 text-center">
      <div className="text-sm">
        <span className="font-bold">銀行口座が設定されていません。</span>
        イベントの出演料を受け取るためには、設定が必要です。
        <Link
          to="/manager/settings/bank"
          className="ml-1 font-bold text-blue-500 hover:underline"
        >
          設定する
        </Link>
      </div>
    </div>
  );
};
