import { useState } from 'react';

import { useInterval } from 'usehooks-ts';

import { SERVICE_TYPE } from './SERVICE_TYPE';
import BgPet from './img/background/bg_pet.png';
import BgPiano from './img/background/bg_piano.png';
import BgShow from './img/background/bg_show.png';

const getBg = () => {
  switch (SERVICE_TYPE) {
    case 'piano':
      return BgPiano;
    case 'pet':
      return BgPet;
    default:
      return BgShow;
  }
};

export const LogoBackground = () => {
  const [hue, setHue] = useState(0);
  useInterval(() => {
    setHue(Math.floor(Math.random() * 360));
  }, 1000);

  return (
    <div
      className="absolute inset-0 bg-cover"
      style={{
        backgroundImage: `url(${getBg()})`,
        filter: `hue-rotate(${hue}deg)`,
      }}
    ></div>
  );
};
