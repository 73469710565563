import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useSWRConfig } from 'swr';

import { api } from '../api';
import { AdminEvent } from '../types';
import { Alert } from './Alert';
import { AdminSetupCardClosed, Card, CardTitle } from './Card';

export const AdminEventSetupEntranceFee: React.FC<{ event: AdminEvent }> = ({
  event,
}) => {
  type Inputs = {
    entrance_fee: number;
  };

  const defaultValues: Inputs = {
    entrance_fee: event.entrance_fee || 0,
  };

  const [isOpened, setIsOpened] = useState(false);

  const [validationFailed, setValidationFailed] = useState(false);

  const { mutate } = useSWRConfig();

  const { register, handleSubmit, reset } = useForm<Inputs>({
    defaultValues,
  });

  const isValid = (data: Inputs) => {
    if (data.entrance_fee < 0) {
      return false;
    }
    return true;
  };

  const onSubmit = async (data: Inputs) => {
    if (!isValid(data)) {
      setValidationFailed(true);
      return;
    }
    await api.patch(`/manager/ceremony/${event.id}/`, data);
    mutate(`/manager/ceremony/${event.id}/`);
    setIsOpened(false);
    setValidationFailed(false);
  };

  const onCancel = () => {
    reset(defaultValues);
    setIsOpened(false);
  };

  if (!isOpened) {
    return (
      <AdminSetupCardClosed
        title="入場料設定"
        onEditButton={() => {
          setIsOpened(true);
        }}
      >
        <div>
          <div>
            入場料：
            {event.entrance_fee ? (
              <span className="font-bold">{event.entrance_fee}円</span>
            ) : (
              <span className="font-bold">無料</span>
            )}
          </div>
        </div>
      </AdminSetupCardClosed>
    );
  }

  return (
    <Card>
      <CardTitle>入場料設定</CardTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2">
          入場料
          <input
            className="mx-2 rounded border border-orange-400 p-1 shadow"
            type="number"
            {...register('entrance_fee')}
          />{' '}
          (円)
        </div>
        <div className="my-1 text-xs text-gray-500">
          入場料を入力してください。
        </div>
        {validationFailed && (
          <Alert variant="warning">
            入場料はゼロまたはプラスの値を入力してください。
          </Alert>
        )}

        <button
          className="mt-3 rounded border border-orange-800 bg-orange-800 px-4 py-1 text-white shadow"
          type="submit"
        >
          保存して次へ
        </button>
        <button
          className="ml-2 rounded border border-orange-800 px-4 py-1 text-orange-800 shadow"
          type="button"
          onClick={onCancel}
        >
          キャンセル
        </button>
      </form>
    </Card>
  );
};
