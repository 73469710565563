import React from 'react';
import { FaPlay } from 'react-icons/fa';

import { ModalPortal } from '../ModalPortal';
import { api } from '../api';
import { useAgoraReceive } from '../hooks/agora/useAgoraReceive';

type Props = {
  token: string;
  channel: string;
  fallback?: React.ReactNode;
  frameDiv?: React.ReactNode;
};

export const LiveReceive: React.FC<Props> = (props) => {
  const getToken = async (uid: number) => {
    const body = { uid };
    console.log({ body });
    const res = await api.post<{ token: string }>(
      `/ceremony/${props.token}/live/`,
      { uid },
    );
    const { token } = res.data;
    return token;
  };
  const { divRef, live, muted, unmute } = useAgoraReceive(
    props.channel,
    getToken,
  );

  return (
    <>
      {!live && props.fallback && <>{props.fallback}</>}
      <div className="absolute inset-0" ref={divRef}></div>
      {live && props.frameDiv}

      {muted && (
        <ModalPortal>
          <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
            <div className="pointer-events-auto flex flex-col items-center">
              <div className="pointer-events-none relative mb-3 whitespace-nowrap rounded bg-orange-300 px-2 py-2 text-black transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-8 before:border-transparent before:border-t-orange-300 before:content-['']">
                ボタンを押してライブの音声を聞く
              </div>
              <button
                className="white flex rounded bg-blue-500 px-2 py-1 text-white items-center"
                onClick={unmute}
              >
                <FaPlay className="mr-2" />
                音声を再生
              </button>
            </div>
          </div>
        </ModalPortal>
      )}
    </>
  );
};
