import React from 'react';
import { Link } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import { AdminEvent } from '../types';

const EventStatus: React.FC<{ event: AdminEvent }> = ({ event }) => {
  if (event.event_status === 'running_after') {
    return (
      <div className="mx-3 rounded-full bg-gray-600 px-3 py-1 text-white shadow">
        終了済(公開中)
      </div>
    );
  }
  if (event.event_status === 'finished') {
    return (
      <div className="mx-3 rounded-full bg-gray-600 px-3 py-1 text-white shadow">
        終了済
      </div>
    );
  }
  if (event.event_status === 'running') {
    return (
      <div className="mx-3 rounded-full bg-orange-600 px-3 py-1 text-white shadow">
        開催中
      </div>
    );
  }
  if (event.event_status === 'running_before') {
    return (
      <div className="mx-3 rounded-full bg-orange-600 px-3 py-1 text-white shadow">
        開催直前
      </div>
    );
  }
  if (event.is_payment_completed && event.completed_payments.length > 0) {
    return (
      <div className="mx-3 rounded-full bg-green-600 px-3 py-1 text-white shadow">
        支払い完了
      </div>
    );
  }
  if (event.is_setup_completed && !event.is_payment_completed) {
    return (
      <div className="mx-3 rounded-full bg-yellow-600 px-3 py-1 text-white shadow">
        支払い待ち
      </div>
    );
  }
  if (event.is_setup_completed) {
    return (
      <div className="mx-3 rounded-full bg-green-600 px-3 py-1 text-white shadow">
        設定完了
      </div>
    );
  }
  return (
    <div className="mx-3 rounded-full bg-blue-500 px-3 py-1 text-white shadow">
      未設定
    </div>
  );
};
function getEventLink(event: AdminEvent) {
  if (event.event_status === 'finished') {
    return `/manager/event/${event.id}/after/`;
  }
  if (event.event_status === 'running') {
    return `/manager/event/${event.id}/realtime/`;
  }
  if (event.is_payment_completed && event.completed_payments.length > 0) {
    return `/manager/event/${event.id}/invite/`;
  }
  return `/manager/event/${event.id}/setup/`;
}
export const AdminEventRow: React.FC<{ event: AdminEvent }> = ({ event }) => {
  const e = event;
  return (
    <Link
      to={getEventLink(e)}
      className="my-2 block rounded border border-gray-300 p-2 text-blue-500 shadow hover:underline"
    >
      <div>{e.event_name || e.pet_name}</div>
      <div className="flex items-center justify-between text-xs">
        <div>
          {e.starts_at
            ? format(parseISO(e.starts_at), 'yyyy/MM/dd HH:mm～')
            : '-'}{' '}
        </div>
        <div>
          <EventStatus event={e} />
        </div>
      </div>
    </Link>
  );
};
