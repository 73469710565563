import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import useSWR from 'swr';

import { api } from '../api';
import { Frame, useFrames } from '../frames';
import { AdminEvent } from '../types';
import { LOGIN_URL } from '../url';
import { useFlag } from '../useFlag';
import { AdminEventHeader } from './AdminEventHeader';
import { Alert } from './Alert';

type PaymentItem = {
  id: string;
  name: string;
  price: number;
};

function getRequiredPayment(event: AdminEvent, frames: Frame[]): PaymentItem[] {
  const options: PaymentItem[] = [];
  if (
    event.ceremony_image_type === 'video' ||
    event.post_media_type === 'video'
  ) {
    options.push({ id: 'video', name: '動画', price: 1000 });
  } else if (
    event.ceremony_image_type === 'image' ||
    event.post_media_type === 'image'
  ) {
    options.push({ id: 'image', name: '画像', price: 500 });
  }

  // TODO: remove this if clause
  // Frame is also being used in live streaming
  if (['video', 'image'].includes(event.ceremony_image_type)) {
    const frame = frames.find((frame) => frame.id === event.ceremony_frame);
    if (frame) {
      options.push({
        id: `frame-${frame.id}`,
        name: 'フレーム',
        price: frame.price,
      });
    }
  }

  if (event.is_archive_enabled === 'yes') {
    options.push({ id: 'archive', name: 'アーカイブ', price: 1000 });
  }

  if (event.live_enabled === 'yes') {
    options.push({ id: 'live', name: 'ライブ配信', price: 1000 });
  }

  return options;
}

const fetcher = (url: string) => api(url).then((res) => res.data);

export const AdminEventPayment: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: event, error } = useSWR<AdminEvent>(
    `/manager/ceremony/${id}/`,
    fetcher,
  );
  const navigate = useNavigate();
  const isFree = useFlag('free_items');
  const frames = useFrames();

  useEffect(() => {
    if (event && event.event_status === 'finished') {
      navigate(`/manager/event/${event.id}/after/`);
    }
    if (event && !event.is_setup_completed) {
      navigate(`/manager/event/${event?.id}/setup/`);
    }
  }, [event, navigate]);

  if (error) {
    if (error.response?.status === 401) {
      window.location.href = LOGIN_URL;
      return null;
    }
    if (error.response?.status === 403) {
      return <div>権限がありません</div>;
    }
    return <div>エラーが発生しました</div>;
  }
  if (!event) {
    return <div>Loading...</div>;
  }
  if (isFree == null) {
    return <div>Loading...</div>;
  }
  if (frames == null) {
    return <div>Loading...</div>;
  }

  const requiredPayment = getRequiredPayment(event, frames);

  const isPaymentCompleted =
    isFree || event.offered
      ? true
      : requiredPayment.every((item) =>
          event.completed_payments.includes(item.id),
        );

  const paymentNotCompleted = requiredPayment.filter(
    (item) => !event.completed_payments.includes(item.id),
  );
  const total = paymentNotCompleted.reduce((sum, item) => sum + item.price, 0);
  const overview = paymentNotCompleted.map((item) => item.name).join(', ');
  const paymentPayload = {
    options: paymentNotCompleted.map((p) => p.id),
    ceremony_pk: event.id,
  };

  if (isPaymentCompleted) {
    return (
      <div className="p-4">
        <AdminEventHeader active="payment" event={event} />

        {isFree && (
          <Alert variant="info">
            現在テスト期間中、全ての利用料は無料です。
          </Alert>
        )}
        {event.offered && (
          <Alert variant="info">
            特別オファーのため、全ての利用料は無料です。
          </Alert>
        )}

        <div className="my-4 text-center text-2xl font-bold">
          お支払いは完了しました。
        </div>
        <div className="my-2 text-center">
          <Link
            to={`/manager/event/${event.id}/invite/`}
            className="rounded bg-orange-300 px-4 py-2 text-lg shadow"
            rel="noreferrer"
          >
            次へ &gt;
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4">
      <AdminEventHeader active="payment" event={event} />
      <div className="mx-auto max-w-screen-md">
        <h2 className="my-4 text-2xl font-bold">お支払い</h2>
        <p>有料オプションのお支払いが必要です。</p>
        {requiredPayment.map((item) => (
          <div
            className="flex items-center justify-between border-t py-2"
            key={item.name}
          >
            <div>{item.name}</div>
            {event.completed_payments.includes(item.id) ? (
              <div className="flex">
                <div className="mx-2 rounded bg-green-500 px-1 text-sm text-white">
                  支払い済
                </div>
                <s>{item.price} 円</s>
              </div>
            ) : (
              <div>{item.price} 円</div>
            )}
          </div>
        ))}

        <div className="flex items-center justify-between border-y-2 py-2 font-bold">
          <div>合計</div>
          <div>{total} 円</div>
        </div>
      </div>

      <form action="/payment/confirm/" method="POST">
        <input type="hidden" name="amount" value={total} />
        <input type="hidden" name="overview" value={overview} />
        <input type="hidden" name="kind" value="ceremony-option" />
        <input
          type="hidden"
          name="payload"
          value={JSON.stringify(paymentPayload)}
        />
        <input type="hidden" name="callback" value={window.location.href} />
        <div className="my-2 text-center">
          <button
            type="submit"
            className="rounded bg-orange-300 px-4 py-2 text-lg shadow"
          >
            購入
          </button>
        </div>
      </form>
    </div>
  );
};
