import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useSWRConfig } from 'swr';

import { api } from '../api';
import { AdminEvent } from '../types';
import { AdminSetupCardClosed, Card, CardTitle } from './Card';

export const AdminEventSetupPrePost: React.FC<{
  event: AdminEvent;
  variant: 'pre' | 'post';
}> = ({ event, variant }) => {
  const currentMuted = {
    pre: event.pre_video_muted,
    post: event.post_video_muted,
  }[variant];
  const currentAnnounce = {
    pre: event.announce_pre,
    post: event.announce_post,
  }[variant];
  const isVideoSelected = {
    pre: event.ceremony_image_type === 'video',
    post: event.post_media_type === 'video',
  }[variant];
  type Inputs = {
    muted: boolean;
    announce: string;
  };

  const defaultValues: Inputs = {
    muted: currentMuted,
    announce: currentAnnounce || '',
  };

  const [isOpened, setIsOpened] = useState(currentAnnounce === null);

  const { mutate } = useSWRConfig();

  const { register, handleSubmit, reset } = useForm<Inputs>({
    defaultValues,
  });

  const onSubmit = async (data: Inputs) => {
    await api.patch(`/manager/ceremony/${event.id}/`, {
      [variant + '_video_muted']: data.muted,
      ['announce_' + variant]: data.announce,
    });
    mutate(`/manager/ceremony/${event.id}/`);
    setIsOpened(false);
  };

  const onCancel = () => {
    reset(defaultValues);
    setIsOpened(false);
  };

  if (!isOpened) {
    return (
      <AdminSetupCardClosed
        title={`イベント${variant === 'pre' ? '前' : '後'}設定`}
        onEditButton={() => {
          setIsOpened(true);
        }}
      >
        <div>
          {isVideoSelected && (
            <div>
              紹介動画の音声をミュート：
              <span className="mb-1 font-bold">
                {currentMuted
                  ? 'する(音声を再生しない)'
                  : 'しない(音声を再生する)'}
              </span>
            </div>
          )}
          <div>
            <div className="mb-1 mt-4">
              イベント開催{variant === 'pre' ? '前' : '後'}メッセージ：
            </div>
            {currentAnnounce ? (
              <div className="whitespace-pre-wrap rounded bg-gray-100 p-2">
                {currentAnnounce}
              </div>
            ) : (
              'なし'
            )}
          </div>
        </div>
      </AdminSetupCardClosed>
    );
  }

  return (
    <Card>
      <CardTitle>イベント{variant === 'pre' ? '前' : '後'}設定</CardTitle>
      <p>
        イベント開催{variant === 'pre' ? '前' : '後'}
        に表示するメッセージが設定できます。
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isVideoSelected && (
          <div className="my-2">
            <input
              type="checkbox"
              {...register('muted')}
              id={variant + 'VideoMuteCheck'}
            />
            <label htmlFor={variant + 'VideoMuteCheck'}>
              紹介動画の音声をミュートする
            </label>
          </div>
        )}
        <div className="my-2">
          <div>開催{variant === 'pre' ? '前' : '後'}メッセージ</div>
          <textarea
            className="w-full max-w-screen-md rounded border border-orange-400 p-1 shadow"
            rows={5}
            {...register('announce')}
          />
        </div>
        <button
          className="rounded border border-orange-800 bg-orange-800 px-4 py-1 text-white shadow"
          type="submit"
        >
          保存
        </button>
        <button
          className="ml-2 rounded border border-orange-800 px-4 py-1 text-orange-800 shadow"
          type="button"
          onClick={onCancel}
        >
          キャンセル
        </button>
      </form>
    </Card>
  );
};
