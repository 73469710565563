import React, { useState } from 'react';

import { api } from './api';

export const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const login = async () => {
    const res = await api.post('/auth/login/', {
      email: email,
      password: password,
    });
    console.log(res.data);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          <div className="card mt-5">
            <div className="card-body">
              <h1 className="card-title text-center">ログイン</h1>
              <form>
                <div className="mb-3">
                  <label className="form-label">メールアドレス</label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onInput={(e) => {
                      setEmail(e.currentTarget.value);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">パスワード</label>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onInput={(e) => {
                      setPassword(e.currentTarget.value);
                    }}
                  />
                </div>
                <div className="d-grid">
                  <button
                    className="btn btn-primary"
                    onClick={login}
                    type="button"
                  >
                    ログイン
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
