import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';

import { HintModalFullScreen } from '../HintModal';

const H2 = ({ children }: { children: React.ReactNode }) => (
  <h2 className="mb-2 text-2xl font-bold">{children}</h2>
);

const H3 = ({ children }: { children: React.ReactNode }) => (
  <h3 className="mb-1 mb-2 mt-3 border-b-2 border-orange-500 text-xl font-bold">
    {children}
  </h3>
);

export const AdminEventFlowHintButton: React.FC = () => (
  <HintModalFullScreen
    content={
      <>
        <H2>イベントの流れ</H2>
        <p>
          あなた(主催者)と参加者の間で、イベントを開催する流れを説明します。
        </p>
        <H3>1. 準備</H3>
        <p>
          事前に、イベントの設定を行う必要があります。
          イベント名やイベントの日時・実施時間・参加者の上限などを設定します。
        </p>
        <p>
          イベント当日はライブ配信を行う他、イベントの前・後に動画の配信を行うことができます。
          動画のアップロードや、ライブ配信のフレームの設定などができます。
          一部の設定項目は、有料オプションとして設定できます。
        </p>
        <H3>2. 支払い</H3>
        <p>
          設定が完了したら、イベントの前に有料オプションの支払いを行います。
        </p>
        <H3>3. 招待</H3>
        <p>
          イベントの参加者を招待します。
          イベントのURLを共有することで、参加者はイベントに参加できます。
        </p>
        <p>
          参加者は、リンクをクリックして、イベントに参加することができます。
          イベント開場は、イベントの開始時間の30分前から開場します。
        </p>
        <H3>4. 当日</H3>
        <p>
          イベントの開始時間になったら、カメラをオンにして、ライブ配信を開始します。
        </p>
        <p>
          イベント中は、参加者からのプレゼントやコメントを受け付けることができます。
        </p>
        <p>
          設定した時間になるか、主催者がイベントを終了すると、イベントは終了します。
        </p>
        <p>
          イベントの終了後、10分間は参加者からのプレゼントやコメントを受け付けることができます。
        </p>
        <H3>5. 終了</H3>
        <p>
          イベントが終了した後は、イベント中のコメントやプレゼントを確認することができます。
        </p>
        <p>
          また、イベント終了ののち、参加者から送られたプレゼントの70%を銀行振り込みにて受け取ることができます。
          <br />※ 銀行口座の設定が必要です。
        </p>
      </>
    }
    className="flex items-center rounded bg-blue-500 px-2 py-1 text-sm text-white shadow"
    hintId="admin-event-flow"
  >
    <FaQuestionCircle className="mr-2" />
    イベントの流れ
  </HintModalFullScreen>
);
