export const ServiceDescription: React.FC = () => (
  <div className="prose m-4">
    <h1>サービス説明</h1>
    <h2>システム使用料</h2>
    <p>参列者が支払った金額に対し20%</p>
    <h2>振込手数料</h2>
    <p>
      <ol>
        <li>参加者支払い合計金額が3000円未満 の場合は振込手数料は600円税別</li>
        <li>3000円以上は400円税別</li>
      </ol>
    </p>
    <h2>振込日</h2>
    <p>毎月10日締め翌月末払い</p>
  </div>
);
